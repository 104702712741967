<template>

  <div class="confirm-page">

    <div
      v-if="loading"
      class="fixed-center text-center">

      <q-spinner-cube
        color="primary"
        size="xl"/>

    </div>

    <div v-else class="confirm-page__wrapper">

      <div v-if="confirmed" class="confirm-page__content">

        <h1>E-mail успешно <span class="text-primary">подтверждён!</span></h1>

        <div class="confirm-page__content__description">
          Спесибо! Теперь вы можете полноценно пользоваться сервисом.<br/>
          Вы будете автоматически перенаправлены в личный кабинет.
        </div>

        <q-btn
          no-caps
          size="lg"
          color="primary"
          class="button-primary"
          :to="{ name: 'main' }">
          Перейти в личный кабинет
        </q-btn>

      </div>

      <div v-else class="confirm-page__content">

        <h1>Что-то пошло <span class="text-primary">не так :(</span></h1>

        <div class="confirm-page__content__description">
          Попробуйте повторить позже.
        </div>

        <q-btn
          no-caps
          size="lg"
          color="primary"
          class="button-primary"
          :to="{ name: 'main' }">
          Перейти в личный кабинет
        </q-btn>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: 'confirm-page',
  props: {
    code: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      confirmed: false,
    };
  },
  mounted() {
    if (this.code) {
      this.$store.dispatch('users/CONFIRM', {
        code: this.code,
      }).then((result) => {
        this.loading = false;
        this.confirmed = result;

        setTimeout(this.redirect, 4000);
      }).catch(() => {
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
  methods: {
    redirect() {
      this.$router.push({ name: 'main' });
    },
  },
};
</script>

<style lang="scss">

  .confirm-page {
    background-image: url("/assets/img/hand-ok.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 420px;
    padding: 0 50px;

    h1 {
      font-size: 46px;
      font-weight: 700;
      line-height: 52px;
      margin: 0 0 24px;
    }

    &__wrapper {
      height: 100vh;
      display: flex;
      margin: 0 auto;
      max-width: 1100px;
      flex-direction: column;
      justify-content: center;
    }

    &__content {

      &__description {
        color: #8E94A3;
        font-size: 16px;
        margin-bottom: 50px;
      }
    }

    .button-primary {
      color: #FFFFFF;
      z-index: 0;
      padding: 0 24px;
      line-height: 48px;
      border-radius: 16px;
      font-size: 16px !important;
      background: linear-gradient(.57turn, #4388FF, #316BFF);

      &:before {
        content: "";
        top: 30px;
        left: 15px;
        right: 15px;
        bottom: 4px;
        opacity: .7;
        z-index: -1;
        filter: blur(35px);
        position: absolute;
        border-radius: 16px;
        background: linear-gradient(.57turn, #4388FF, #316BFF);
      }
    }
  }

</style>
