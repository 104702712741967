<template>

  <div class="page-block-editor">

    <template v-if="value.type === 'header'">

      <ui-input-file
        label="Аватарка"
        :value="value.image"
        @remove="$emit('input', {...value, image: null})"
        @input="v => $emit('input', {...value, image: v})"/>

      <ui-input
        label="Название"
        :value="value.title"
        @input="v => $emit('input', {...value, title: v})"/>

      <ui-input
        type="textarea"
        label="Описание"
        :value="value.text"
        @input="v => $emit('input', {...value, text: v})"/>

      <ui-color
        label="Цвет текста"
        :value="value.textColor"
        @input="v => $emit('input', {...value, textColor: v})"/>

    </template>

    <template v-if="value.type === 'button'">

      <h6>Текст кнопки</h6>

      <ui-input
        label="Заголовок"
        :value="value.title"
        @input="v => $emit('input', {...value, title: v})"/>

      <ui-input
        label="Описание"
        :value="value.subtitle"
        @input="v => $emit('input', {...value, subtitle: v})"/>

      <h6>Cсылка</h6>

      <ui-input
        label="Ссылка"
        :value="value.action"
        @input="v => $emit('input', {...value, action: v})"/>

      <h6>Иконка</h6>

      <ui-input-icon
        :value="value.icon"
        @input="v => $emit('input', {...value, icon: v})"/>

      <ui-color
        label="Цвет иконки"
        :value="value.iconColor"
        @input="v => $emit('input', {...value, iconColor: v})"/>

      <ui-color
        label="Цвет фона"
        :value="value.iconBgColor"
        @input="v => $emit('input', {...value, iconBgColor: v})"/>

      <h6>Цвет кнопки</h6>

      <ui-color
        label="Цвет фона"
        :value="value.bgColor"
        @input="v => $emit('input', {...value, bgColor: v})"/>

      <ui-color
        label="Цвет текста"
        :value="value.textColor"
        @input="v => $emit('input', {...value, textColor: v})"/>

    </template>

    <template v-if="value.type === 'social'">

      <h6>Тип кнопки</h6>

      <ui-select
        emit-value
        map-options
        :items="socials"
        label="Тип кнопки"
        :value="value.preset"
        @input="v => $emit('input', {...value, preset: v})">

        <template v-slot:option="scope">

          <q-item
            v-bind="scope.itemProps"
            v-on="scope.itemEvents">

            <q-item-section avatar>
              <q-img :src="'https://fs.bicard.pro/icon/' + scope.opt.value + '.svg'"/>
            </q-item-section>

            <q-item-section>
              <q-item-label v-html="scope.opt.label" />
            </q-item-section>

          </q-item>
        </template>

      </ui-select>

      <h6>Текст кнопки</h6>

      <ui-input
        label="Заголовок"
        :value="value.title"
        @input="v => $emit('input', {...value, title: v})"/>

      <ui-input
        label="Описание"
        :value="value.subtitle"
        @input="v => $emit('input', {...value, subtitle: v})"/>

      <h6>Cсылка</h6>

      <ui-input
        :label="currentSocial.actionLabel"
        :value="value.action"
        @input="v => $emit('input', {...value, action: v})"/>

      <h6>Цвет кнопки</h6>

      <ui-color
        label="Цвет фона"
        :value="value.bgColor"
        @input="v => $emit('input', {...value, bgColor: v})"/>

      <ui-color
        label="Цвет текста"
        :value="value.textColor"
        @input="v => $emit('input', {...value, textColor: v})"/>

    </template>

  </div>

</template>

<script>
import BlockSocialsCollection from '../../collections/block-socials-collection.json';
import UiInputFile from '../ui/ui-input-file';
import UiInputIcon from '../ui/ui-input-icon';
import UiSelect from '../ui/ui-select';
import UiInput from '../ui/ui-input';
import UiColor from '../ui/ui-color';

export default {
  name: 'page-block-editor',
  components: {
    UiColor,
    UiInput,
    UiSelect,
    UiInputIcon,
    UiInputFile,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    socials() {
      return BlockSocialsCollection;
    },
    currentSocial() {
      if (this.value.type !== 'social') return {};
      return this.socials.find(({ value }) => value === this.value.preset) || {};
    },
  },
};
</script>

<style lang="scss">

  .page-block-editor {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: repeat(14, auto);

    h6 {
      font-weight: 400;
      margin: 16px 0 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

</style>
