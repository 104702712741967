import Client from '../../plugins/graphql-client';

export async function FETCH_SESSION_ITEM(context) {
  const actionArgument = {
    name: 'me',
    fields: [
      'id',
      'name',
      'email',
      'subscribeTo',
      'subscribeType',
      {
        name: 'userpic',
        fields: ['id', 'server', 'path'],
      },
    ],
  };

  const client = Client();
  const response = await client.query(actionArgument);

  if (response) context.commit('SET_USER', response);

  return response || null;
}

export async function NEW_PASS(context, { oldPass, newPass, token }) {
  const actionArgument = {
    name: 'newPass',
    args: token ? { newPass, token } : { oldPass, newPass },
    fields: [
      'token',
      {
        name: 'user',
        fields: [
          'id',
          'email',
        ],
      },
    ],
  };

  const client = Client();

  const response = await client.mutation(actionArgument);
  if (response) context.commit('SET_SESSION_ITEM', response);

  return response || null;
}

export async function SIGN_IN(context, payload) {
  const actionArgument = {
    name: 'login',
    args: payload,
    fields: [
      'token',
      {
        name: 'user',
        fields: [
          'id',
          'email',
        ],
      },
    ],
  };

  const client = Client();

  const response = await client.mutation(actionArgument);
  if (response) context.commit('SET_SESSION_ITEM', response);

  return response || null;
}

export async function SIGN_UP(context, payload) {
  const actionArgument = {
    name: 'signUp',
    args: payload,
    fields: [
      'token',
      {
        name: 'user',
        fields: [
          'id',
          'email',
        ],
      },
    ],
  };

  const client = Client();
  const response = await client.mutation(actionArgument);
  if (response) context.commit('SET_SESSION_ITEM', response);

  return response || null;
}

export async function CONFIRM(context, { code }) {
  const actionArgument = {
    name: 'confirm',
    args: { code },
  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}

export async function PASS_RESET(context, { email }) {
  const actionArgument = {
    name: 'passReset',
    args: { email },

  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}
