<template>

  <q-scroll-area class="page-design-editor">

    <h6>Шапка</h6>

    <ui-color
      label="Фон шапки"
      :value="value.headerBg"
      @input="v => $emit('input', {...value, headerBg: v})"/>

    <ui-color
      label="Текст в шапке"
      :value="value.headerTextColor"
      @input="v => $emit('input', {...value, headerTextColor: v})"/>

    <h6>Страница</h6>

    <ui-color
      label="Фон страницы"
      :value="value.pageBg"
      @input="v => $emit('input', {...value, pageBg: v})"/>

    <ui-color
      label="Цвет текста"
      :value="value.pageTextColor"
      @input="v => $emit('input', {...value, pageTextColor: v})"/>

    <h6>Кнопки</h6>

    <ui-color
      label="Фон кнопки"
      :value="value.bgColor"
      @input="v => $emit('input', {...value, bgColor: v})"/>

    <ui-color
      label="Текст на кнопке"
      :value="value.textColor"
      @input="v => $emit('input', {...value, textColor: v})"/>

    <ui-color
      label="Цвет иконки"
      :value="value.iconColor"
      @input="v => $emit('input', {...value, iconColor: v})"/>

    <ui-color
      label="Фон иконки"
      :value="value.iconBgColor"
      @input="v => $emit('input', {...value, iconBgColor: v})"/>

    <h6>Скругление углов</h6>

    <q-slider
      label
      :min="0"
      :max="35"
      :value="value.borderRadius"
      @input="v => $emit('input', {...value, borderRadius: v})"/>

    <h6>Тень</h6>

    <ui-color
      label="Цвет тени"
      :value="value.shadowColor"
      @input="v => $emit('input', {...value, shadowColor: v})"/>

    <h6>Размытие тени</h6>

    <q-slider
      label
      :min="0"
      :max="50"
      :value="value.shadowSize"
      @input="v => $emit('input', {...value, shadowSize: v})"/>

    <h6>Прозрачность тени</h6>

    <q-slider
      label
      :min="0"
      :max="100"
      :value="parseFloat(value.shadowOpacity || '') * 100"
      @input="v => $emit('input', {...value, shadowOpacity: '' + v/100})"/>

  </q-scroll-area>

</template>

<script>
import UiColor from '../ui/ui-color';

export default {
  name: 'page-design-editor',
  components: {
    UiColor,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style lang="scss">

  .page-design-editor {
    width: 100%;
    height: 100%;

    .scroll > .absolute {
      padding: 32px;
      display: grid;
      grid-gap: 10px;
      grid-template-rows: repeat(14, auto);
    }

    h6 {
      font-weight: 400;
      margin: 16px 0 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

</style>
