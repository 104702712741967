<template>

  <div class="ui-input-file">

    <!-- Input Tile -->
    <q-img
      :ratio="1"
      width="56px"
      height="56px"
      class="ui-input-file__tile"
      :src="value && value.path ? getFileUrl(value) : ''">

      <!-- Activator -->
      <q-btn
        flat
        size="lg"
        icon="eva-plus"
        :loading="loading"
        text-color="primary"
        @click="$refs.file.click()"
        class="ui-input-file__activator"
        v-if="!value || !value.path || !value.server"/>

      <input
        ref="file"
        type="file"
        v-show="false"
        @change="fileHandler"/>

      <iframe
        ref="frame"
        style="display:none;"/>

    </q-img>

    <div class="ui-input-file__label__wrapper">

      <div class="ui-input-file__label">
        {{ label }}
      </div>

      <div class="ui-input-file__label__subtitle text-body2">
        {{ subtitle }}
      </div>

    </div>

    <div
      v-if="value && $listeners.remove"
      class="ui-input-file__label__wrapper">

      <q-btn
        icon="eva-trash-outline"
        @click="$emit('remove')"/>

    </div>

  </div>

</template>

<script>
import Mixins from '../../mixins';
import { simpleFileType } from '../../utils';

export default {
  name: 'ui-input-file',
  mixins: [Mixins],
  props: {
    label: {
      type: String,
      default: 'Файл',
    },
    subtitle: {
      type: String,
      default: 'png/jpg до 20МБ',
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    fileHandler({ target }) {
      try {
        const file = target.files.length
          ? target.files[0]
          : null;

        this.input(file);
      } catch (e) {
        this.$error(e);
      }
    },
    input(file) {
      if (!file) return;
      if (!this.checkType(file)) return;
      if (!this.checkSize(file)) return;

      this.loading = true;
      this.$store.dispatch('files/UPLOAD', {
        file,
      }).then((itm) => {
        this.loading = false;
        this.$emit('input', itm);
      }).catch((e) => {
        this.loading = false;
        this.$error(e);
      });
    },
    checkType(file) {
      const type = simpleFileType(file);

      const allowed = (type === 'image');

      if (!allowed) {
        this.$q.notify({
          message: 'Недопустимый тип файла.',
          color: 'negative',
        });

        return false;
      }

      return true;
    },
    checkSize(file) {
      const max = 25;

      if (file.size > max * 1024 * 1024) {
        this.$q.notify({
          message: `Размер файла не должен превышать ${max} мегабайт.`,
          color: 'negative',
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">

  .ui-input-file {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 56px 1fr auto;

    .q-img {
      border-radius: 12px;
    }

    &__activator {
      width: 100%;
      height: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      border-radius: 16px;
      flex-direction: column;
      justify-content: center;
      color: $primary !important;
      background: #F2F5FC !important;
    }

    &__label {

      &__wrapper {
        display: flex;
        line-height: 16px;
        flex-direction: column;
        justify-content: center;

        .q-btn {
          width: 44px;
          height: 44px;
          border-radius: 12px;
          color: $primary !important;
          background: #F2F5FC !important;

          &__wrapper {
            padding: 0;
          }
        }
      }

      &__subtitle {
        color: #8E94A3;
      }
    }
  }

</style>
