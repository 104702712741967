const routes = [
  {
    path: '/',
    component: () => import('../layouts/layout.vue'),
    children: [
      {
        path: '',
        name: 'main',
        component: () => import('../pages/main-page.vue'),
        meta: { title: 'Личный кабинет' },
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../pages/profile-page.vue'),
        meta: { title: 'Мой профиль' },
      },
      {
        path: 'cards',
        name: 'cards',
        component: () => import('../pages/cards-page.vue'),
        meta: { title: 'Мои визитки' },
      },
      {
        path: 'card/:id/edit',
        name: 'card-editor',
        component: () => import('../pages/card-editing-page.vue'),
        meta: { title: 'Редактирование визитки' },
        props: true,
      },
      {
        path: 'card/:id/settings',
        name: 'card-settings',
        component: () => import('../pages/card-settings-page.vue'),
        meta: { title: 'Настройки визитки' },
        props: true,
      },
      {
        path: 'card/:id/download',
        name: 'card-download',
        component: () => import('../pages/card-download-page.vue'),
        meta: { title: 'Скачать визитку' },
        props: true,
      },
      {
        path: 'pages',
        name: 'pages',
        component: () => import('../pages/pages-page.vue'),
        meta: { title: 'Мои страницы' },
      },
      {
        path: 'page/:slug/edit',
        name: 'page-editor',
        component: () => import('../pages/page-editing-page.vue'),
        meta: { title: 'Редактирование страницы' },
        props: true,
      },
      {
        path: 'page/:slug/design',
        name: 'page-design',
        component: () => import('../pages/page-design-page.vue'),
        meta: { title: 'Дизайн страницы' },
        props: true,
      },
      {
        path: 'page/:slug/settings',
        name: 'page-settings',
        component: () => import('../pages/page-settings-page.vue'),
        meta: { title: 'Настройки страницы' },
        props: true,
      },
      {
        path: 'stat',
        name: 'stat',
        component: () => import('../pages/main-page.vue'),
        meta: { title: 'Статистика' },
      },
      {
        path: 'finance',
        name: 'finance',
        component: () => import('../pages/finance-page.vue'),
        meta: { title: 'Финансы' },
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('../pages/help-page.vue'),
        meta: { title: 'Помощь' },
      },
    ],
  },
  {
    path: '/',
    component: () => import('../layouts/dialog-layout.vue'),
    children: [
      {
        path: 'login',
        name: 'dialog-login',
        component: () => import('../pages/dialog/login-page.vue'),
        meta: { title: 'Авторизация' },
      },
      {
        path: 'signup',
        name: 'dialog-signup',
        component: () => import('../pages/dialog/signup-page.vue'),
        meta: { title: 'Регистрация' },
      },
      {
        path: 'reset',
        name: 'dialog-reset',
        component: () => import('../pages/dialog/reset-page.vue'),
        meta: { title: 'Сброс пароля' },
      },
      {
        path: 'pass-reset/:token',
        name: 'dialog-pass-reset',
        component: () => import('../pages/dialog/pass-reset-page.vue'),
        meta: { title: 'Сброс пароля' },
        props: true,
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: '/login',
  },
  {
    path: '/confirm/:code',
    component: () => import('../pages/system/confirm-page.vue'),
    props: true,
  },
  {
    path: '/error/:code',
    name: 'error',
    component: () => import('../pages/system/error-page.vue'),
    props: true,
  },
];

routes.push({
  path: '*',
  component: () => import('../pages/system/error-page.vue'),
});

export default routes;
