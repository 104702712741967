<template>

  <q-card flat class="page-card">

    <div class="page-card__info">

      <q-img
          width="56px"
          height="56px"
          v-if="imageLink"
          :src="imageLink"/>

      <h4>
        {{ name }}
      </h4>

    </div>

    <div class="page-card__description text-body1">
      {{ description | cut(140) }}
    </div>

    <div class="page-card__actions">

      <q-btn
          no-caps
          icon="eva-edit"
          label="Редактировать"
          @click="$router.push({ name: 'page-editor', params: { slug } })"/>

      <q-btn
          no-caps
          icon="fas fa-qrcode"
          label="QR код страницы"
          @click="$emit('makeQR')"/>

      <div class="page-card__actions__link">

        <q-btn
            no-caps
            type="a"
            :label="slug"
            target="__blank"
            icon="eva-external-link-outline"
            :href="`http://bicard.pro/${slug}`"/>

        <q-btn
            icon="far fa-clone"
            @click="linkCopy(`http://bicard.pro/${slug}`)"/>

      </div>

      <q-btn
        no-caps
        v-if="false"
        @click="goStat"
        label="Статистика"
        icon="far fa-chart-bar"/>

      <q-btn
        v-else
        no-caps
        label="Настройки"
        icon="fas fa-cogs"
        @click="$router.push({ name: 'page-settings', params: { slug } })"/>

    </div>

  </q-card>

</template>

<script>
import { Notify } from 'quasar';
import Mixins from '../../mixins';

export default {
  name: 'page-card',
  mixins: [Mixins],
  components: {},
  props: {
    imageLink: {
      type: String,
    },
    name: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: 'bicard.pro',
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  methods: {
    goStat() {
      Notify.create({
        color: 'primary',
        icon: 'eva-info-outline',
        message: 'Недостаточно данных',
        actions: [{ icon: 'eva-close', color: 'white' }],
      });
    },
    linkCopy(text) {
      if (window.clipboardData && window.clipboardData.setData) {
        this.$info('Ссылка скопирована в буфер обмена');
        return window.clipboardData.setData('Text', text);
      }

      if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = text;
        textarea.style.position = 'fixed';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.$info('Ссылка скопирована в буфер обмена');
          return true;
        } catch (ex) {
          console.warn('Copy to clipboard failed.', ex);
          this.$error('Невозможно скопировать ссылку');
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
  },
};
</script>

<style lang="scss">

  .page-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__info {
      display: flex;
      flex-wrap: nowrap;
      line-height: 56px;

      .q-img {
        margin-right: 16px;
        border-radius: 50%;
      }

      h4 {
        display: flex;
        align-items: center;
      }
    }

    &__description {
      color: #8E94A3;
    }

    &__actions {
      display: grid;
      row-gap: 10px;
      column-gap: 16px;
      grid-template-rows: 44px 44px;
      grid-template-columns: 1fr 1fr;

      .q-btn {
        line-height: 44px;
        border-radius: 12px;
        background-color: #F2F5FC;

        &__wrapper {
          padding: 0 10px;
        }

        .q-icon {
          color: $primary;
        }
      }

      &__link {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 44px;
      }
    }
  }

</style>
