<template>

  <div class="main-page layout__wrapper">

    <div class="main-page__widgets">

      <q-card flat class="main-page__widget">

        Тариф

        <h2>
          {{ subscribeTo ? tariff.name : 'Free' }}
        </h2>

        <div
          class="text-caption text-secondary">
          {{ subscribeTo || 'Бессрочно' }}
        </div>

      </q-card>

      <q-card flat class="main-page__widget">

        Просмотры

        <h2 v-if="viewsPeerDay">
          {{ viewsPeerDay[currentDay] || 0 }}

          <template v-if="(viewsPeerDay[currentDay] || 0) > (viewsPeerDay[previousDay] || 0)">
            <q-icon color="positive" name="fas fa-caret-up" class="q-mb-xs"/>
          </template>

          <template
            v-if="(viewsPeerDay[currentDay] || 0) < (viewsPeerDay[previousDay] || 0)">
            <q-icon color="negative" name="fas fa-caret-down" v-if="viewsPeerDay[currentDay]"/>
          </template>
        </h2>

        <div class="text-caption text-secondary">
          Учитываются просмотры со всех ваших страниц за сегодня
        </div>

      </q-card>

      <q-card flat class="main-page__widget">

        Конверсии

        <h2 v-if="clicksPeerDay">
          {{ clicksPeerDay[currentDay] || 0 }}

          <template v-if="(clicksPeerDay[currentDay] || 0) > (clicksPeerDay[previousDay] || 0)">
            <q-icon color="positive" name="fas fa-caret-up" class="q-mb-xs"/>
          </template>

          <template v-if="(clicksPeerDay[currentDay] || 0) < (clicksPeerDay[previousDay] || 0)">
            <q-icon color="negative" name="fas fa-caret-down" v-if="clicksPeerDay[currentDay]"/>
          </template>
        </h2>

        <div class="text-caption text-secondary">
          Учитываются клики по кнопкам со всех ваших страниц за сегодня
        </div>

      </q-card>

    </div>

    <q-card flat class="main-page__chart">

      <canvas id="chart"/>

    </q-card>

  </div>

</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import countBy from 'lodash/countBy';
import { Chart, registerables } from 'chart.js';
import tariffsCollection from '../collections/tariffs-collection.json';

export default {
  name: 'main-page',
  components: {},
  props: {},
  data() {
    return {
      days: [],
      views: [],
      clicks: [],
      chart: null,
      viewsPeerDay: null,
      clicksPeerDay: null,
      currentDay: moment().format('dd').toLowerCase(),
      previousDay: moment().subtract(1, 'day').format('dd').toLowerCase(),
    };
  },
  beforeCreate() {
    moment.locale('ru');
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);

    this.$store.commit('events/SET_PAGINATION', {
      limit: 999,
      descending: true,
    });

    this.$store.dispatch('events/FETCH_ITEMS', {
      args: {
        dateFrom: '' + moment()
          .startOf('day')
          .subtract(6, 'days')
          .valueOf(),
      },
      fields: [
        'type',
        'createdAt',
        {
          name: 'data',
          fields: ['eventType'],
        },
      ],
    }).then(this.setData);

    this.initChart();
  },
  computed: {
    ...mapState({
      user: (state) => state.users.sessionItem,
      events: (state) => state.events.items,
    }),
    tariff() {
      return tariffsCollection[this.user && this.user.subscribeType ? this.user.subscribeType : 'free'];
    },
    subscribeTo() {
      if (!this.user) return '';
      if (!this.user.subscribeTo) return '';
      if (this.tariff.name === 'Free') return '';
      if (moment().valueOf() > this.user.subscribeTo) return '';

      let date = moment(+this.user.subscribeTo);
      if (!date.isValid()) return '';

      return `Активен до ${date.format('DD.MM.YYYY')}`;
    },
  },
  methods: {
    setData(actions) {
      if (!actions) return;

      actions.forEach((action) => {
        const item = {
          ...action,
          day: moment(+action.createdAt).format('dd'),
        };

        if (item.type === 'view') this.views.push(item);
        else this.clicks.push(item);
      });

      this.viewsPeerDay = countBy(this.views, 'day');
      this.clicksPeerDay = countBy(this.clicks, 'day');

      const viewsCounts = [];
      this.days.forEach((label) => {
        viewsCounts.push(this.viewsPeerDay[label.toLowerCase()] || 0);
      });

      const clicksCounts = [];
      this.days.forEach((label) => {
        clicksCounts.push(this.clicksPeerDay[label.toLowerCase()] || 0);
      });

      if (this.chart) {
        this.chart.data.datasets.forEach((dataset) => {
          if (dataset.label === 'Посетители') dataset.data = viewsCounts;
          if (dataset.label === 'Конверсии') dataset.data = clicksCounts;
        });
        this.chart.update();
      }
    },
    initChart() {
      Chart.register(...registerables);

      for (let i = 6; i >= 0; i--) {
        const dayOfWeek = moment()
          .subtract(i, 'days')
          .format('dd');
        this.days.push(dayOfWeek.toUpperCase());
      }

      if (!this.chart) this.chart = new Chart('chart', {
        type: 'line',
        data: {
          labels: this.days,
          datasets: [
            {
              label: 'Посетители',
              tension: 0.4,
              borderWidth: 4,
              borderColor: '#4388FF',
              data: [],
            },
            {
              label: 'Конверсии',
              tension: 0.4,
              borderWidth: 4,
              borderColor: '#21BA45',
              data: [],
            },
          ],
        },
        options: {
          height: 500,
          scales: {
            y: {
              suggestedMin: 3,
              suggestedMax: 5,
            },
          },
        },
      });
    },
  },
};
</script>

<style lang="scss">

  .main-page {

    &__widgets {
      display: grid;
      grid-gap: 32px;
      margin-bottom: 32px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__widget {
      display: grid;
      grid-gap: 16px;
      align-items: center;
      grid-template-rows: 20px 56px 20px;

      h2 {
        display: flex;
        align-items: center;
      }
    }

    &__chart {
      width: 100%;
      height: 564px;
    }
  }

</style>
