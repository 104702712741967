<template>

  <div class="help-page layout__wrapper">

    <layout-faq :items="faqItems"/>

  </div>

</template>

<script>
import LayoutFaq from '../components/layout/layout-faq';

export default {
  name: 'help-page',
  components: { LayoutFaq },
  props: {},
  data() {
    return {
      faqItems: [
        {
          label: 'Что произойдёт, если я сменю адрес страницы? Старый QR код перестанет работать?',
          text: 'Нет, в QR коде зашифрован не адрес страницы, а уникальный идентификатор. При смене '
            + 'адреса страницы идентификатор не меняется и QR код остаётся рабочим.',
        },
        {
          label: 'Какой срок жизни моей страницы?',
          text: '<p>Ваша страница будет продолжать работать всё время, пока существует сервис '
            + '<a href="https://bicard.pro" target="_blank">bicard.pro</a>.</p>'
            + '<p>Администрация сервиса оставляет за собой право освобождать занятые '
            + 'пользователями адреса страниц, если их владельцы более 6 месяцев не осуществляли '
            + 'оплату подписки. При этом страница будет так же доступна по QR коду.</p>',
        },
        {
          label: 'Что произойдёт, если я не продлю подписку?',
          text: 'Ваша страница продолжит отображаться по ссылке, но все кнопки и ссылки на ней '
            + 'станут неактивными. Посетители смогут зайти на вашу страницу, но не смогут перейти '
            + 'по ссылкам размещённым на вашей странице.',
        },
        {
          label: 'Почему на моей странице пропали все кнопки и ссылки?',
          text: 'Скорее всего у вас закончилась подписка. Если подписка оплачена, а кнопки всё-равно '
            + 'не отображаются - напишите нам на почту <a href="mailto:info@bicard.pro">info@bicard.pro</a>',
        },
        {
          label: 'У вас возникли проблемы с сервисом или вы не нашли ответа на свой вопрос?',
          text: 'Напишите нам на почту <a href="mailto:info@bicard.pro">info@bicard.pro</a>',
        },
      ],
    };
  },
  methods: {},
};
</script>
