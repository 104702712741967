<template>

  <!-- Drawer Menu -->
  <div class="layout-drawer-menu">

    <q-img
      width="127px"
      height="25px"
      src="/assets/img/logo-white.svg"
      @click="$router.push({name: 'main'})"
      class="layout-drawer-menu__logo cursor-pointer"/>

    <q-list
      padding
      v-if="value.length"
      class="layout-drawer-menu__navigation">

      <template
        v-for="(item, index) in value">

        <q-item
          clickable
          :key="index"
          :to="item.to"
          :class="{
            'q-router-link--exact-active':
              (item.to === '/pages' && $route.name === 'page-editor')
              || (item.to === '/pages' && $route.name === 'page-design')
              || (item.to === '/pages' && $route.name === 'page-settings')
              || (item.to === '/cards' && $route.name === 'card-editor')
              || (item.to === '/cards' && $route.name === 'card-settings')
              || (item.to === '/cards' && $route.name === 'card-download')
          }">

          <q-item-section avatar>

            <q-icon
              tag="svg"
              :name="item.icon"/>

          </q-item-section>

          <q-item-section>
            {{ item.label }}
          </q-item-section>

        </q-item>

      </template>

    </q-list>

    <q-list
      padding
      v-if="submenu.length"
      class="layout-drawer-menu__navigation additional">

      <template
        v-for="(item, index) in submenu">

        <q-item
          clickable
          :key="index"
          @click="goTo(item.to)">

          <q-item-section avatar>

            <q-icon
              tag="svg"
              :name="item.icon"/>

          </q-item-section>

          <q-item-section>
            {{ item.label }}
          </q-item-section>

        </q-item>

      </template>

    </q-list>

  </div>

</template>

<script>

export default {
  name: 'layout-drawer-menu',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    submenu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goTo(href) {
      const link = document.createElement('a');

      link.href = href;
      link.target = '_blank';

      document.body.appendChild(link);

      link.click();
    },
  },
};

</script>

<style lang="scss">

  .q-drawer {
    background: linear-gradient(.10turn, #316BFF, #4388FF);
  }

  .layout-drawer-menu {
    display: grid;
    min-height: 100%;
    grid-template-rows: 100px auto 1fr;

    &__logo {
      margin-left: 32px;
      align-self: center;
    }

    &__navigation {
      padding-left: 12px;

      &.additional {
        align-self: end;
        font-size: 14px;
      }
    }

    .q-item {
      height: 54px;
      color: #FFFFFF;
      padding: 0 20px;
      transition: unset;
      line-height: 14px;
      position: relative;
      border-radius: 16px 0 0 16px;

      .q-focus-helper {
        display: none;
      }

      &__section--avatar {
        min-width: auto;
      }
    }

    .q-router-link--exact-active {
      color: $primary !important;
      background-color: #F2F5FC;

      &:before {
        right: 0;
        top: -17px;
        content: '';
        width: 18px;
        height: 18px;
        position: absolute;
        background-image: url("/assets/img/corner.svg");
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:after {
        right: 0;
        content: '';
        width: 18px;
        height: 18px;
        bottom: -17px;
        position: absolute;
        transform: scaleY(-1);
        transition: opacity .3s linear;
        background-image: url("/assets/img/corner.svg");
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }

</style>
