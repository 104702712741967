import axios from 'axios';
import Cookies from 'quasar/src/plugins/Cookies.js';;
import query from './query';
import mutation from './mutation';
import paginateQuery from './paginate-query';

const server = {
  development: 'http://dev-api.bicard.pro',
  production: 'https://api.bicard.pro',
};

const client = axios.create({
  baseURL: server[process.env.NODE_ENV],
});

// Add a request interceptor
client.interceptors.request.use((conf) => {
  const config = { ...conf };
  config.headers.Accept = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  config.headers['X-Requested-With'] = 'XMLHttpRequest';

  const accessToken = Cookies.get('accessToken');
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
}, (error) => Promise.reject(error));

export default function () {
  return {
    query: query(client),
    mutation: mutation(client),
    paginateQuery: paginateQuery(client),
  };
}
