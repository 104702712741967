import Client from '../../plugins/graphql-client';

export async function SET_IMAGE(context, {
  pageId,
  file,
}) {
  if (file !== null && !file.id) {
    console.error('file id is required');
    return;
  }

  const actionArgument = {
    name: 'updatePage',
    args: {
      image: file ? {
        id: file.id,
        path: file.path,
        server: file.server,
      } : null,
      id: pageId,
    },
    fields: [
      {
        name: 'image',
        fields: [
          'id',
          'name',
          'path',
          'server',
        ],
      },
    ],
  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}
