var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-block-button",style:({
    boxShadow: _vm.boxShadow,
    borderRadius: _vm.design.borderRadius + 'px',
    backgroundColor: _vm.bgColor || _vm.design.bgColor,
  })},[(_vm.icon || _vm.imageLink)?_c('div',{staticClass:"page-block-button__img",style:({
      borderRadius: (_vm.design.borderRadius - 2) + 'px',
      color: _vm.imageLink ? null : (_vm.iconColor || _vm.design.iconColor),
      backgroundColor: _vm.imageLink ? null : (_vm.iconBgColor || _vm.design.iconBgColor),
    })},[_c('q-img',{attrs:{"src":_vm.imageLink}},[(_vm.icon)?_c('q-icon',{attrs:{"name":_vm.icon}}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"page-block-button__content",style:({ color: _vm.textColor || _vm.design.textColor })},[_c('div',{staticClass:"page-block-button__content__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.subtitle)?_c('div',{staticClass:"page-block-button__content__subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }