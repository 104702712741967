<template>

  <div class="page-design-page">

    <page-toolbar/>

    <div class="page-design-page__wrapper">

      <!-- Templates List -->
      <q-card flat class="page-design-page__templates" style="padding: 0">

        <q-scroll-area>

          <page-design-card
            :key="i"
            :value="design"
            v-for="(design, i) in designsList"
            @click="$store.commit('pages/INPUT', { ...item, design })"/>

        </q-scroll-area>

      </q-card>

      <!-- Phone Area -->
      <page-viewer :value="item"/>

      <!-- Sidebar -->
      <q-card
        flat
        v-if="item"
        style="padding: 0;"
        class="page-design-page__sidebar">

        <page-design-editor
          :value="item.design"
          @input="design => $store.commit('pages/INPUT', { ...this.item, design } )"/>

      </q-card>

    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import { blockFields } from '../utils';
import PageDesignEditor from '../components/pages/page-design-editor';
import PageDesigns from '../collections/page-designs-collection.json';
import PageDesignCard from '../components/pages/page-design-card';
import PageToolbar from '../components/pages/page-toolbar';
import PageViewer from '../components/pages/page-viewer';

export default {
  name: 'page-design-page',
  components: {
    PageViewer,
    PageToolbar,
    PageDesignCard,
    PageDesignEditor,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      designType: 'all',
    };
  },
  computed: {
    ...mapGetters('pages', {
      item: 'value',
    }),
    designsList() {
      const type = this.designType;
      return type === 'all'
        ? [
          ...PageDesigns.colors,
        ]
        : PageDesigns[type];
    },
  },
  created() {
    this.$store.commit('pages/RESET_ITEM');
    this.$store.dispatch('pages/FETCH_ITEM', {
      args: { slug: this.slug },
      fields: [
        'id',
        'slug',
        'name',
        'published',
        'description',
        {
          name: 'image',
          fields: ['server', 'path'],
        },
        {
          name: 'design',
          fields: [
            'fontFamily',
            'pageBg',
            'headerBg',
            'pageTextColor',
            'headerTextColor',
            'shadowSize',
            'borderRadius',
            'shadowOpacity',
            'shadowColor',
            'bgColor',
            'textColor',
            'iconColor',
            'iconBgColor',
          ],
        },
        {
          name: 'items',
          fields: blockFields(),
        },
      ],
    });
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);
  },
};
</script>

<style lang="scss">

  .page-design-page {
    padding: 0 32px;

    &__wrapper {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: 1fr 384px 1fr;
    }

    &__templates {
      width: 100%;
      height: 100%;
      overflow: hidden;
      max-width: 320px;
      justify-self: start;

      .q-scrollarea {
        width: 100%;
        height: 100%;

        .absolute {
          padding: 32px;
        }
      }
    }

    &__sidebar {
      width: 100%;
      height: 100%;
      max-width: 320px;
      justify-self: end;
    }
  }

</style>
