var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-drawer-menu"},[_c('q-img',{staticClass:"layout-drawer-menu__logo cursor-pointer",attrs:{"width":"127px","height":"25px","src":"/assets/img/logo-white.svg"},on:{"click":function($event){return _vm.$router.push({name: 'main'})}}}),(_vm.value.length)?_c('q-list',{staticClass:"layout-drawer-menu__navigation",attrs:{"padding":""}},[_vm._l((_vm.value),function(item,index){return [_c('q-item',{key:index,class:{
          'q-router-link--exact-active':
            (item.to === '/pages' && _vm.$route.name === 'page-editor')
            || (item.to === '/pages' && _vm.$route.name === 'page-design')
            || (item.to === '/pages' && _vm.$route.name === 'page-settings')
            || (item.to === '/cards' && _vm.$route.name === 'card-editor')
            || (item.to === '/cards' && _vm.$route.name === 'card-settings')
            || (item.to === '/cards' && _vm.$route.name === 'card-download')
        },attrs:{"clickable":"","to":item.to}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"tag":"svg","name":item.icon}})],1),_c('q-item-section',[_vm._v(" "+_vm._s(item.label)+" ")])],1)]})],2):_vm._e(),(_vm.submenu.length)?_c('q-list',{staticClass:"layout-drawer-menu__navigation additional",attrs:{"padding":""}},[_vm._l((_vm.submenu),function(item,index){return [_c('q-item',{key:index,attrs:{"clickable":""},on:{"click":function($event){return _vm.goTo(item.to)}}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"tag":"svg","name":item.icon}})],1),_c('q-item-section',[_vm._v(" "+_vm._s(item.label)+" ")])],1)]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }