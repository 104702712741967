<template>

  <q-item
    v-on="scope.itemEvents"
    v-bind="scope.itemProps"
    class="finance-period-item">

    <q-item-section>

      <q-item-label class="flex justify-between">

        <div>{{ scope.opt.month }}</div>

        <div>

          <s
            v-if="scope.opt.oldPrice > 0"
            class="text-caption text-secondary q-mr-sm"
          >{{ scope.opt.oldPrice | formatMoney(undefined, 0) }}</s>

          {{ scope.opt.price | formatMoney(undefined, 0) }}

        </div>

      </q-item-label>

    </q-item-section>

  </q-item>

</template>

<script>
export default {
  name: 'finance-period-item',
  components: {},
  props: {
    scope: Object,
    default: () => ({
      opt: {},
      itemProps: {},
      itemEvents: {},
    }),
  },
};
</script>
