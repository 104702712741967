<template>

  <div
    class="card-design-card"
    @click="$emit('click')">

    <div
      class="card-design-card__front"
      :style="{ background: value.background }">

      <div
        class="card-design-card__avatar"
        :style="{ background: value.color }"/>

      <div
        class="card-design-card__title"
        :style="{ background: value.color }"/>

      <div
        class="card-design-card__subtitle"
        :style="{ background: value.color }"/>

    </div>

    <div
      class="card-design-card__back"
      :style="{
        color: value.backColor,
        background: value.backBackground,
      }">

      <q-icon name="fas fa-qrcode"/>

    </div>

  </div>

</template>

<script>
export default {
  name: 'card-design-card',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">

  .card-design-card {
    z-index: 1;
    width: 100%;
    height: 60px;
    display: grid;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    border-radius: 12px;
    margin-bottom: 12px;
    grid-template-columns: 1fr 60px;
    box-shadow: 0 20px 50px rgba(49, 107, 255, .12);

    &__avatar {
      top: 17px;
      left: 17px;
      width: 26px;
      height: 26px;
      position: absolute;
      border-radius: 50%;
    }

    &__title {
      top: 17px;
      left: 56px;
      right: 77px;
      height: 10px;
      position: absolute;
      border-radius: 3px;
    }

    &__subtitle {
      top: 35px;
      left: 56px;
      right: 87px;
      height: 8px;
      opacity: .5;
      position: absolute;
      border-radius: 3px;
    }

    &__back {
      top: 0;
      right: 0;
      bottom: 0;
      width: 60px;
      display: flex;
      font-size: 22px;
      position: absolute;
      align-items: center;
      justify-content: center;
    }

  }

</style>
