<template>

  <div
    class="page-block-button"
    :style="{
      boxShadow,
      borderRadius: design.borderRadius + 'px',
      backgroundColor: bgColor || design.bgColor,
    }">

    <div
      v-if="icon || imageLink"
      class="page-block-button__img"
      :style="{
        borderRadius: (design.borderRadius - 2) + 'px',
        color: imageLink ? null : (iconColor || design.iconColor),
        backgroundColor: imageLink ? null : (iconBgColor || design.iconBgColor),
      }">

      <q-img
        :src="imageLink">

        <q-icon
          v-if="icon"
          :name="icon"/>

      </q-img>

    </div>

    <div
      class="page-block-button__content"
      :style="{ color: textColor || design.textColor }">

      <div class="page-block-button__content__title">
        {{ title }}
      </div>

      <div
        v-if="subtitle"
        class="page-block-button__content__subtitle">
        {{ subtitle }}
      </div>

    </div>

  </div>

</template>

<script>
import { hexToRgb } from '../../../utils';

export default {
  name: 'page-block-button',
  components: {},
  props: {
    icon: {
      type: String,
      default: '',
    },
    imageLink: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    design: {
      type: Object,
      default: () => ({}),
    },
    bgColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconBgColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    boxShadow() {
      const { shadowSize, shadowColor, shadowOpacity } = this.design;
      const rgb = hexToRgb(shadowColor);

      if (!shadowSize || !shadowColor || !shadowOpacity || !rgb) return undefined;
      return `0 3px ${shadowSize / 4}px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${shadowOpacity})`;
    },
  },
};
</script>

<style lang="scss">

  .page-block-button {
    padding: 11px;
    display: grid;
    margin-bottom: 16px;
    grid-template-columns: auto 1fr;

    &__img {
      width: 48px;
      height: 48px;

      .q-img {
        width: 100%;
        height: 100%;

        &__content {
          display: flex;
          font-size: 26px;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    &__content {
      display: flex;
      padding-left: 16px;
      flex-direction: column;
      justify-content: center;

      &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
      }

      &__subtitle {
        opacity: .5;
        font-size: 14px;
        margin-top: 4px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

</style>
