<template>

  <div class="page-viewer">

    <div class="page-viewer__phone">

      <q-scroll-area class="page-viewer__phone__wrapper">

        <template v-if="value">

          <div
            :style="{
              height: headerBgHeight,
              background: value.design ? value.design.headerBg : null,
            }"
            class="page-viewer__phone__header-bg"/>

          <page-block
            :key="index"
            ref="blocks"
            :value="block"
            :design="value.design"
            v-for="(block, index) in value.items"/>

        </template>

      </q-scroll-area>

    </div>

  </div>

</template>

<script>
import PageBlock from './page-block';

export default {
  name: 'page-viewer',
  components: { PageBlock },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tryIndx: 0,
      headerBgHeight: '',
    };
  },
  mounted() {
    this.setHeaderBgHeight();
  },
  methods: {
    setHeaderBgHeight() {
      if (!this.$refs.blocks || !this.$refs.blocks.length) {
        if (this.tryIndx < 9) setTimeout(this.setHeaderBgHeight, 100);
        else console.error('$refs.blocks is undefined');

        this.tryIndx++;
        return;
      }

      const block = this.$refs.blocks[1];

      const eh = block.$el.clientHeight;
      const et = block.$el.getBoundingClientRect().top;
      const pt = block.$el.parentElement.getBoundingClientRect().top;

      this.headerBgHeight = `${(et - pt) + (eh / 2)}px`;
    },
  },
};
</script>

<style lang="scss">

 .page-viewer {
   padding-top: 12px;

   &__phone {
     width: 360px;
     height: 780px;
     position: relative;
     border-radius: 40px;

     &:before {
       content: '';
       z-index: 1;
       top: -12px;
       right: -12px;
       width: 360px;
       height: 780px;
       position: absolute;
       border-radius: 40px;
       pointer-events: none;
       box-sizing: content-box;
       border: 12px solid #FFFFFF;
     }

     &__header-bg {
       top: 0;
       left: 0;
       width: 100%;
       position: absolute;
     }

     &__wrapper {
       width: 100%;
       min-height: 100%;
       border-radius: 28px;
       box-sizing: border-box;
       border: 1px solid #F2F5FC;
       background-color: #FFFFFF;

       .scroll {
         top: 0;
         left: -200px;
         position: absolute;
         border-radius: 28px;
         box-sizing: border-box;
         width: 560px !important;
         height: 780px !important;
         padding: 0 0 0 200px;

         .absolute {
           width: 360px !important;
           padding: 35px 20px 35px 20px;
         }
       }
     }

     .q-scrollarea__bar, .q-scrollarea__thumb {
       width: 0;
     }

     &__add-button {
       padding: 0;
       bottom: 12px;
       position: absolute;
       border-radius: 50%;
       left: calc(50% - 22px);
     }
   }
 }

</style>
