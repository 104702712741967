<template>

  <div class="card-settings-page">

    <card-toolbar/>

    <q-card
      flat
      v-if="item"
      class="profile-page">

      <h4 class="layout__title">
        Основные настройки
      </h4>

      <div class="layout__row q-mb-lg">

        <!-- Text Field - Login -->
        <ui-input
          :value="item.name"
          label="Название"
          @input="v => $store.commit('cards/INPUT', {...item, name: v})"/>

        <!-- Select - page -->
        <ui-select
          emit-value
          map-options
          :items="pages"
          label="Страница"
          class="full-width"
          :value="item.page ? item.page.id : null"
          @input="id => $store.commit('cards/INPUT', {...item, page: { id }})"/>

      </div>

      <h4 class="layout__title">
        Удаление визитки
      </h4>

      <div class="layout__row">

        <q-btn
          color="red"
          class="button-primary"
          label="Удалить визитку"
          @click="$refs.removeDialog.show({}, 'Удаление визитки')"/>

      </div>

    </q-card>

    <!-- Remove Dialog -->
    <ui-dialog
      :width="520"
      ref="removeDialog">

      Визитка будет удалена без возможности восстановления! Удалить визитку?

      <q-btn
        no-caps
        unelevated
        color="red"
        slot="actions"
        @click="remove()"
        :loading="loading"
        class="q-mt-lg full-width">
        Удалить визитку
      </q-btn>

    </ui-dialog>

    <q-spinner
      size="3em"
      v-if="!item"
      color="primary"
      :thickness="10"
      class="fixed-center"/>

  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CardToolbar from '../components/cards/card-toolbar';
import UiDialog from '../components/ui/ui-dialog';
import UiSelect from '../components/ui/ui-select';
import UiInput from '../components/ui/ui-input';

export default {
  name: 'card-settings-page',
  components: {
    UiInput,
    UiSelect,
    UiDialog,
    CardToolbar,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('cards', {
      item: 'value',
    }),
    ...mapState({
      pages: (state) => state.pages.items.map((itm) => ({
        label: itm.name,
        value: itm.id,
      })),
    }),
  },
  created() {
    this.$store.commit('cards/RESET_ITEM');
    this.$store.dispatch('cards/FETCH_ITEM', {
      args: { id: this.id },
      fields: [
        'id',
        'name',
        {
          name: 'page',
          fields: ['id', 'slug'],
        },
      ],
    });
    this.$store.dispatch('pages/FETCH_ITEMS', {
      fields: [
        'id',
        'name',
      ],
    });
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);
  },
  methods: {
    remove() {
      this.loading = true;

      this.$store.dispatch('cards/REMOVE_ITEM', {
        args: { id: this.item.id },
      }).then(() => {
        this.loading = false;
        this.$info('Визитка успешно удалена');
        this.$router.push({ name: 'cards' });
      }).catch((e) => {
        console.error(e);
        this.loading = false;
        this.$refs.removeDialog.hide();
        this.$error('Не удалось удалить визитку');
      });
    },
  },
};
</script>

<style lang="scss">

  .card-settings-page {

    padding: 0 32px;

    &__wrapper {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: 1fr 384px 1fr;
    }
  }

</style>
