<template>

  <q-layout
    class="layout"
    view="lhr LpR fFf">

    <q-drawer
      side="left"
      :width="280"
      show-if-above
      v-model="drawer"
      v-if="!loading && sessionItem">

      <drawer-menu
        :value="menu"
        :submenu="submenu"/>

    </q-drawer>

    <layout-header
      :title="title"
      :badge="badge"
      :name="sessionItem ? sessionItem.name : ''"
      :image-link="sessionItem && sessionItem.userpic ? getFileUrl(sessionItem.userpic) : ''"/>

    <q-page-container v-if="!loading && sessionItem">

      <router-view/>

    </q-page-container>

    <q-spinner
      size="3em"
      v-if="loading"
      color="primary"
      :thickness="10"
      class="fixed-center"/>

  </q-layout>

</template>

<script>
import moment from 'moment';
import { Cookies } from 'quasar';
import { mapGetters } from 'vuex';
import SubMenuCollection from '../collections/main-submenu-collection.json';
import tariffsCollection from '../collections/tariffs-collection.json';
import MenuCollection from '../collections/main-menu-collection.json';
import DrawerMenu from '../components/layout/layout-drawer-menu';
import LayoutHeader from '../components/layout/layout-header';
import Mixins from '../mixins';

export default {
  name: 'layout',
  components: {
    DrawerMenu,
    LayoutHeader,
  },
  mixins: [Mixins],
  props: {},
  data() {
    return {
      drawer: false,
      loading: false,
      menu: MenuCollection,
      submenu: SubMenuCollection,
    };
  },
  computed: {
    ...mapGetters('global', {
      title: 'title',
    }),
    ...mapGetters('users', {
      sessionItem: 'sessionItem',
    }),
    badge() {
      const badgeFree = 'Тариф: Free Бессрочно';
      if (this.tariff.name !== 'Free') {
        if (!this.sessionItem) return badgeFree;
        if (!this.sessionItem.subscribeTo) return badgeFree;

        const today = moment().valueOf();
        if (today > +this.sessionItem.subscribeTo) return badgeFree;
      } else return badgeFree;

      return `Тариф: ${this.tariff.name} `
        + (this.tariff.name !== 'Free' ? `до ${this.subscribeTo}` : '');
    },
    tariff() {
      return tariffsCollection[this.sessionItem && this.sessionItem.subscribeType ? this.sessionItem.subscribeType : 'free'];
    },
    subscribeTo() {
      if (!this.sessionItem) return '';
      if (!this.sessionItem.subscribeTo) return 'Бессрочно';

      let date = moment.unix(this.sessionItem.subscribeTo);
      if (!date.isValid()) return '';

      date = moment.unix(+this.sessionItem.subscribeTo / 1000);
      return `Активен до ${date.format('DD.MM.YYYY')}`;
    },
  },
  created() {
    if (Cookies.has('accessToken')) {
      this.$store.dispatch(
        'users/FETCH_SESSION_ITEM',
      ).then((me) => {
        this.loading = false;
        if (!me) this.$router.push({ name: 'dialog-login' });
      }).catch((e) => {
        this.loading = false;
        console.error(e);
      });
    } else {
      this.$router.push({ name: 'dialog-login' });
    }

    const icons = {
      'app:home': 'svguse:/assets/icons.svg#home',
      'app:card': 'svguse:/assets/icons.svg#card',
      'app:page': 'svguse:/assets/icons.svg#page',
      'app:stat': 'svguse:/assets/icons.svg#stat',
      'app:help': 'svguse:/assets/icons.svg#help',
      'app:file': 'svguse:/assets/icons.svg#file',
      'app:none': 'svguse:/assets/icons.svg#image',
      'app:finance': 'svguse:/assets/icons.svg#finance',
    };

    this.$q.iconMapFn = (iconName) => {
      const icon = icons[iconName];
      if (icon) return { icon };
    };
  },
  methods: {},
};
</script>

<style lang="scss">

  .layout {
    color: #18232F;

    &__wrapper {
      margin: 0 auto;
      padding: 0 32px;
      max-width: 1100px;
    }

    &__title {
      width: 100%;
      margin-bottom: 24px;
    }

    &__row {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .q-layout__section--marginal {
    color: unset;
  }

</style>
