<template>

  <q-scroll-area class="card-design-editor">

    <h6>Лицевая сторона</h6>

    <ui-input-file
      label="Логотип"
      :value="value.logo"
      subtitle="png/svg до 20МБ"
      @remove="$emit('input', {...value, logo: null})"
      @input="v => $emit('input', {...value, logo: v})"/>

    <ui-input
      label="Основной текст (Название/ФИО)"
      :value="value.title"
      @input="v => $emit('input', {...value, title: v})"/>

    <ui-input
      label="Мелкий текст (Должность/направление)"
      :value="value.subtitle"
      @input="v => $emit('input', {...value, subtitle: v})"/>

    <ui-color
      label="Цвет фона"
      :value="value.background"
      @input="v => $emit('input', {...value, background: v})"/>

    <ui-color
      label="Цвет текста"
      :value="value.color"
      @input="v => $emit('input', {...value, color: v})"/>

    <h6>Обратная сторона</h6>

    <ui-color
      label="Цвет фона"
      :value="value.backBackground"
      @input="v => $emit('input', {...value, backBackground: v})"/>

    <ui-color
      label="Цвет QR кода"
      :value="value.backColor"
      @input="v => $emit('input', {...value, backColor: v})"/>

  </q-scroll-area>

</template>

<script>
import UiInputFile from '../ui/ui-input-file';
import UiColor from '../ui/ui-color';
import UiInput from '../ui/ui-input';

export default {
  name: 'card-design-editor',
  components: {
    UiInput,
    UiColor,
    UiInputFile,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">

  .card-design-editor {
    width: 100%;
    height: 100%;

    .scroll > .absolute {
      padding: 32px;
      display: grid;
      grid-gap: 10px;
      grid-template-rows: repeat(14, auto);
    }

    h6 {
      font-weight: 400;
      margin: 16px 0 4px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

</style>
