<template>

  <div class="layout__wrapper">

    <page-toolbar/>

    <q-card
      flat
      v-if="item"
      class="profile-page">

      <h4 class="layout__title">
        Основные настройки и SEO
      </h4>

      <div class="layout__row q-mb-lg">

        <!-- Col -->
        <div>

          <ui-input-file
            ref="file"
            label="Аватар"
            :value="item.image"
            @input="fileHandler"
            @remove="removeImage"/>

        </div>

        <!-- Col -->
        <div>

          <!-- Text Field - Login -->
          <ui-input
            :value="item.name"
            label="Название (title)"
            @input="v => $store.commit('pages/INPUT', {...item, name: v})"/>

          <!-- Text Field - Login -->
          <ui-input
            label="Адрес"
            class="q-mt-lg"
            :value="item.slug"
            @input="v => $store.commit('pages/INPUT', {...item, slug: v})">

            <div slot="prepend" class="text-body2">
              http://bicard.pro/
            </div>

          </ui-input>

        </div>

        <!-- Col -->
        <div>

          <!-- Text Field - Login -->
          <ui-input
            type="textarea"
            :value="item.description"
            label="Краткое описание (description)"
            @input="v => $store.commit('pages/INPUT', {...item, description: v})"/>

        </div>

      </div>

      <h4 class="layout__title">
        Удаление страницы
      </h4>

      <div class="layout__row">

        <q-btn
          color="red"
          class="button-primary"
          label="Удалить страницу"
          @click="$refs.removeDialog.show({}, 'Удаление страницы')"/>

      </div>

    </q-card>

    <!-- Remove Dialog -->
    <ui-dialog
      :width="520"
      ref="removeDialog">

      Страница будет удалена без возможности восстановления! Удалить страницу?

      <q-btn
        no-caps
        unelevated
        color="red"
        slot="actions"
        @click="remove()"
        :loading="loading"
        class="q-mt-lg full-width">
        Удалить страницу
      </q-btn>

    </ui-dialog>

    <q-spinner
      size="3em"
      v-if="!item"
      color="primary"
      :thickness="10"
      class="fixed-center"/>

  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import PageToolbar from '../components/pages/page-toolbar';
import UiInputFile from '../components/ui/ui-input-file';
import UiDialog from '../components/ui/ui-dialog';
import UiInput from '../components/ui/ui-input';

export default {
  name: 'page-settings-page',
  components: {
    UiInput,
    UiDialog,
    PageToolbar,
    UiInputFile,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('pages', {
      item: 'value',
    }),
    ...mapState({
      user: (state) => state.users.sessionItem,
    }),
    isFree() {
      return !(this.user && this.user.subscribeType && this.user.subscribeType !== 'free');
    },
  },
  created() {
    this.$store.commit('pages/RESET_ITEM');
    this.$store.dispatch('pages/FETCH_ITEM', {
      args: { slug: this.slug },
      fields: [
        'id',
        'slug',
        'name',
        'description',
        {
          name: 'image',
          fields: ['id', 'server', 'path'],
        },
      ],
    });
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);
  },
  methods: {
    fileHandler(file) {
      this.$refs.file.loading = true;
      this.$store.commit('pages/SET_LOADING', true);
      this.$store.dispatch('pages/SET_IMAGE', {
        file,
        pageId: this.item.id,
      }).then((data) => {
        this.$refs.file.loading = false;
        this.$store.commit('pages/RESET_LOADING');
        this.$store.commit('pages/INPUT', { ...this.item, image: data.image });
      }).catch((e) => {
        this.$store.commit('pages/RESET_LOADING');
        this.$refs.file.loading = false;
        this.$error(e);
      });
    },
    remove() {
      this.loading = true;

      this.$store.dispatch('pages/REMOVE_ITEM', {
        args: { id: this.item.id },
      }).then(() => {
        this.loading = false;
        this.$info('Страница успешно удалена');
        this.$router.push({ name: 'pages' });
      }).catch((e) => {
        console.error(e);
        this.loading = false;
        this.$refs.removeDialog.hide();
        this.$error('Не удалось удалить страницу');
      });
    },
    removeImage() {
      this.$refs.file.loading = true;
      this.$store.commit('pages/SET_LOADING', true);
      this.$store.dispatch('pages/SET_IMAGE', {
        file: null,
        pageId: this.item.id,
      }).then(() => {
        this.$refs.file.loading = false;
        this.$store.commit('pages/RESET_LOADING');
        this.$store.commit('pages/INPUT', { ...this.item, image: null });
      }).catch((e) => {
        this.$store.commit('pages/RESET_LOADING');
        this.$refs.file.loading = false;
        this.$error(e);
      });
    },
  },
};
</script>

<style lang="scss">

  .page-settings-page {
    padding: 0 32px;
  }

</style>
