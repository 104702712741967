<template>

  <div
    :class="{ inline }"
    class="card-viewer">

    <div
      ref="front"
      class="card-viewer__card front"
      :class="{
        'q-mr-lg': inline,
        'q-mb-lg': !inline
      }"/>

    <div
      ref="back"
      class="card-viewer__card back"/>

  </div>

</template>

<script>
// import axios from 'axios';
import TextToSVG from 'text-to-svg';
import { SVG } from '@svgdotjs/svg.js';
import Font from '../../assets/fonts/Gilroy-Medium.ttf';
import FontRegular from '../../assets/fonts/Gilroy-Regular.ttf';
import QRCode from '../../plugins/qr-code';
import { getFileUrl } from '../../utils';

export default {
  name: 'card-viewer',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      height: null, // 90x50 (to export +2mm 94x54)
      svgBack: null,
      svgFront: null,
      qrCodeGenerator: null,

      // data
      qr: null,
      logo: null,
      title: null,
      subtitle: null,
    };
  },
  computed: {
    data() {
      // logo: File
      // logoX: String
      // logoY: String
      // title: String
      // titleX: String
      // titleY: String
      // subtitle: String
      // subtitleX: String
      // subtitleY: String
      // color: String
      // background: String
      // backLogoX: String
      // backLogoY: String
      // backTitle: String
      // backTitleX: String
      // backTitleY: String
      // backSubtitle: String
      // backSubtitleX: String
      // backSubtitleY: String
      // backColor: String
      // backBackground: String
      return this.value && this.value.data
        ? this.value.data
        : {};
    },
    card() {
      return this.$refs.card;
    },
  },
  watch: {
    value() {
      this.rebuild();
    },
  },
  mounted() {
    this.$nextTick(this.resize);
    this.$nextTick(this.rebuild);
    window.addEventListener('onresize', this.resize);
  },
  methods: {
    resize() {
      const item = this.$refs.front;

      if (!item) return;

      const width = item.clientWidth;
      const height = Math.round((width / 90) * 50);

      this.height = `${height}px`;
    },
    async rebuild() {
      if (this.qr) this.qr.remove();
      if (this.logo) this.logo.remove();
      if (this.title) this.title.remove();
      if (this.subtitle) this.subtitle.remove();

      if (!this.svgFront) {
        this.svgFront = SVG()
          .addTo(this.$refs.front)
          .size('100%', this.height)
          .viewbox(0, 0, 1063, 591)
          .attr({ style: `background: ${this.data.background}` });
      } else {
        this.svgFront
          .size('100%', this.height)
          .attr({ style: `background: ${this.data.background}` });
      }

      if (!this.svgBack) {
        this.svgBack = SVG()
          .addTo(this.$refs.back)
          .size('100%', this.height)
          .viewbox(0, 0, 1063, 591)
          .attr({
            id: 'qrcode',
            style: `background: ${this.data.backBackground}`,
          });
      } else {
        this.svgBack
          .size('100%', this.height)
          .attr({ style: `background: ${this.data.backBackground}` });
      }

      if (this.data.logo) {
        const link = getFileUrl(this.data.logo);
        this.getDataUrl(link, (dataUrl) => {
          this.logo = this.svgFront
            .image(dataUrl)
            .size(260, 260)
            .move(401.5, 90);
        });
      }

      if (this.data.title) {
        TextToSVG.load(Font, (err, converter) => {
          console.log(err);
          if (err) return;

          const path = converter.getD(this.data.title, {
            fontSize: 48,
            anchor: 'center baseline',
          });

          this.title = this.svgFront
            .path(path)
            .center(531.5, 420)
            .fill(this.data.color);
        });
      }

      if (this.data.subtitle) {
        TextToSVG.load(FontRegular, (err, converter) => {
          console.log(err);
          if (err) return;

          const path = converter.getD(this.data.subtitle, {
            fontSize: 36,
            anchor: 'center baseline',
          });

          this.subtitle = this.svgFront
            .path(path)
            .center(531.5, 480)
            .fill(this.data.color);
        });
      }

      if (!this.value || !this.value.page || !this.value.page.id) this.qr = this.svgBack
        .text('Для создания QR кода\r\nустановите связь со страницей\r\nв настройках визитки')
        .font({
          family: 'Gilroy',
          anchor: 'middle',
          weight: 500,
          size: 300,
        })
        .attr({
          y: 1600,
          x: 4050,
          fill: this.data ? this.data.backColor : '#ffffff',
        });
      else {
        if (this.qrCodeGenerator) {
          this.qrCodeGenerator.clear();
          this.qrCodeGenerator = null;
        }

        this.$nextTick(() => {
          this.qrCodeGenerator = new QRCode('qrcode', {
            y: '20%',
            useSVG: true,
            width: '100%',
            height: '60%',
            correctLevel: 0,
            text: 'https://bicard.pro/' + this.value.page.id,
            color: this.data.backColor || '#000000',
          });
        });
      }
    },
    download() {
      this.downloadFront();
      this.downloadBack();
    },
    downloadFront() {
      if (!this.svgFront) {
        this.$error('SVG объект не определён');
        return;
      }

      const svgData = this.svgFront.svg();
      const dom = new DOMParser().parseFromString(svgData, 'text/xml');
      const svg = dom.documentElement;

      svg.removeAttribute('xmlns:svgjs');
      svg.setAttribute('width', '1063px');
      svg.setAttribute('height', '591px');

      const childs = svg.childNodes;
      for (let i = 0; i < childs.length; i++) {
        const node = svg.childNodes[i];
        node.removeAttribute('svgjs:data');

        const nodeChilds = node.childNodes;
        for (let indx = 0; indx < nodeChilds.length; indx++) {
          const n = svg.childNodes[i].childNodes[indx];
          n.removeAttribute('svgjs:data');
        }
      }

      const svgBlob = new Blob([svg.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement('a');

      downloadLink.href = svgUrl;
      downloadLink.download = 'Визитка (лицевая сторона).svg';

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    },
    downloadBack() {
      if (!this.svgBack) {
        this.$error('SVG объект не определён');
        return;
      }

      const svgData = this.svgBack.svg();
      const dom = new DOMParser().parseFromString(svgData, 'text/xml');
      const svg = dom.documentElement;

      svg.removeAttribute('xmlns:svgjs');
      svg.setAttribute('width', '1063px');
      svg.setAttribute('height', '591px');

      const svgBlob = new Blob([svg.outerHTML], { type: 'image/svg+xml;charset=utf-8' });
      const svgUrl = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement('a');

      downloadLink.href = svgUrl;
      downloadLink.download = 'Визитка (обратная сторона).svg';

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    },
    getDataUrl(src, callback) {
      let img = new Image();

      img.crossOrigin = 'Anonymous';

      // eslint-disable-next-line
      img.onload = function () {
        let canvas = document.createElement('CANVAS');
        let ctx = canvas.getContext('2d');

        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);

        const data = canvas.toDataURL();

        callback(data);
      };

      img.src = src;

      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
  },
};
</script>

<style lang="scss">

  .card-viewer {

    &.inline {
      display: flex;
    }

    &__card {
      width: 100%;

      svg {
        border-radius: 16px;
        background-color: #FFFFFF;
      }
    }
  }

</style>
