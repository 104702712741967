<template>

  <div class="page-editing-page">

    <page-toolbar/>

    <div class="page-editing-page__wrapper">

      <q-space/>

      <!-- Phone Area -->
      <div class="page-editing-page__phone-area">

        <div class="page-editing-page__phone">

          <q-scroll-area class="page-editing-page__phone__wrapper">

            <template v-if="item">

              <div
                v-if="item.design"
                :style="{
                  height: headerBgHeight,
                  background: item.design.headerBg,
                }"
                class="page-editing-page__phone__header-bg"/>

              <page-block
                :key="index"
                ref="blocks"
                :value="block"
                :first="index === 0"
                :design="item.design"
                @moveUp="moveBlockUp(index)"
                @moveDown="moveBlockDown(index)"
                :active="index === activeItemIndex"
                v-for="(block, index) in item.items"
                @select="() => activeItemIndex = index"
                :last="index === item.items.length - 1"
                @remove="$refs.blockRemoveDialog.show(
                { index, name: block.title },
                'Удаление блока',
              )"/>

            </template>

          </q-scroll-area>

          <!-- Add Button -->
          <q-btn
            round
            color="primary"
            icon="eva-plus"
            class="page-editing-page__phone__add-button"
            @click="$refs.addButtonDialog.show({}, 'Выберите объект')"/>

        </div>

      </div>

      <!-- Sidebar -->
      <q-card
        flat
        v-if="activeItemIndex !== null"
        class="page-editing-page__sidebar">

        <page-block-editor
          @input="input"
          v-if="item.items[activeItemIndex]"
          :value="item.items[activeItemIndex]"/>

      </q-card>

    </div>

    <!-- Add Block Dialog -->
    <ui-dialog
      :width="460"
      ref="addButtonDialog">

      <div class="page-editing-page__block-types-dialog">

        <page-block-type-button
          :value="type"
          :key="type.id"
          @click="addBlock"
          v-for="type in blockTypes"/>

      </div>

      <div slot="actions"/>

    </ui-dialog>

    <!-- Remove Dialog -->
    <ui-dialog
      :width="520"
      ref="blockRemoveDialog">

      <template
        slot-scope="{ item }">

        Вы уверены что хотите безвозвратно удалить блок{{ item.name ? ' ' + item.name : '' }}?

        <q-btn
          no-caps
          unelevated
          color="red"
          class="q-mt-lg full-width"
          @click="removeBlock(item.index)">
          Удалить блок
        </q-btn>

      </template>

      <div slot="actions"/>

    </ui-dialog>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import remove from 'lodash/remove';
import cloneDeep from 'lodash/cloneDeep';
import BlockTypesCollection from '../collections/block-types-collection.json';
import BlockModelCollection from '../collections/block-model-collection.json';
import PageBlockTypeButton from '../components/pages/page-block-type-button';
import PageBlockEditor from '../components/pages/page-block-editor';
import PageToolbar from '../components/pages/page-toolbar';
import PageBlock from '../components/pages/page-block';
import UiDialog from '../components/ui/ui-dialog';
import { blockFields } from '../utils';

export default {
  name: 'page-editing-page',
  components: {
    UiDialog,
    PageBlock,
    PageToolbar,
    PageBlockEditor,
    PageBlockTypeButton,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      setHeaderIndex: 0,
      headerBgHeight: '0px',
      activeItemIndex: null,
    };
  },
  computed: {
    ...mapGetters('pages', {
      item: 'value',
    }),
    blockTypes() {
      const collection = cloneDeep(BlockTypesCollection);

      if (!this.item) return collection;

      let items = cloneDeep(this.item.items || []);

      const index = items.findIndex(({ type }) => type === 'header');
      if (index >= 0) remove(collection, ({ id }) => id === 'header');

      return collection;
    },
  },
  created() {
    this.$store.commit('pages/RESET_ITEM');
    this.$store.dispatch('pages/FETCH_ITEM', {
      args: { slug: this.slug },
      fields: [
        'id',
        'slug',
        'name',
        'description',
        {
          name: 'image',
          fields: ['id', 'server', 'path'],
        },
        {
          name: 'design',
          fields: [
            'fontFamily',
            'pageBg',
            'headerBg',
            'pageTextColor',
            'headerTextColor',
            'shadowSize',
            'borderRadius',
            'shadowOpacity',
            'shadowColor',
            'bgColor',
            'textColor',
            'iconColor',
            'iconBgColor',
          ],
        },
        {
          name: 'items',
          fields: blockFields(),
        },
      ],
    });
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);

    this.setHeaderBgHeight();
  },
  methods: {
    setHeaderBgHeight() {
      if (!this.$refs.blocks || !this.$refs.blocks.length) {
        if (this.setHeaderIndex < 4) {
          setTimeout(this.setHeaderBgHeight, 300);
          this.setHeaderIndex++;
        }
        return;
      }

      const block = this.$refs.blocks[1];

      const eh = block.$el.clientHeight;
      const et = block.$el.getBoundingClientRect().top;
      const pt = block.$el.parentElement.getBoundingClientRect().top;

      this.headerBgHeight = `${(et - pt) + (eh / 2)}px`;
    },
    input(value) {
      const index = this.activeItemIndex;
      const items = this.item.items.map((item, i) => (i === index ? value : item));

      this.$store.commit(
        'pages/INPUT',
        { ...this.item, items },
      );
    },
    addBlock(type) {
      let items = cloneDeep(this.item.items);

      // Check if header exist
      if (type === 'header') {
        const index = items.findIndex((itm) => itm.type === 'header');
        if (index >= 0) {
          this.$error('На странице уже есть шапка');
          return;
        }
      }

      let item = { ...BlockModelCollection, type };

      if (type === 'header') item = {
        ...item,
        title: this.item.name,
        image: this.item.image,
        text: 'Описание страницы',
      };

      if (type === 'button') item = {
        ...item,
        icon: 'eva-globe-outline',
        title: 'Заголовок кнопки',
        subtitle: 'Подпись для кнопки',
      };

      if (type === 'social') item = {
        ...item,
        preset: 'whatsapp',
        title: 'Напишите мне!',
      };

      // Шапку ставим в начало
      if (type === 'header') items = [item, ...items];
      else items.push(item);

      this.$store.commit(
        'pages/INPUT',
        { ...this.item, items },
      );

      this.activeItemIndex = items.length - 1;
      this.$refs.addButtonDialog.hide();
    },
    removeBlock(index) {
      let items = [...this.item.items];
      items.splice(index, 1);

      this.$store.commit(
        'pages/INPUT',
        { ...this.item, items },
      );

      this.activeItemIndex = null;
      this.$refs.blockRemoveDialog.hide();
    },
    arrayMove(items, index, newIndex) {
      let arr = cloneDeep(items);

      const item = arr.splice(index, 1)[0];
      arr.splice(newIndex, 0, item);

      return arr;
    },
    moveBlockUp(index) {
      this.activeItemIndex = index - 1;
      const items = this.arrayMove(this.item.items, index, index - 1);

      this.$store.commit(
        'pages/INPUT',
        { ...this.item, items },
      );
    },
    moveBlockDown(index) {
      this.activeItemIndex = index + 1;
      const items = this.arrayMove(this.item.items, index, index + 1);

      this.$store.commit(
        'pages/INPUT',
        { ...this.item, items },
      );
    },
  },
};
</script>

<style lang="scss">

  .page-editing-page {
    padding: 0 32px;

    &__wrapper {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: 1fr 384px 1fr;
    }

    &__phone {
      width: 360px;
      height: 780px;
      position: relative;
      border-radius: 40px;

      &-area {
        padding-top: 12px;
      }

      &:before {
        content: '';
        z-index: 1;
        top: -12px;
        right: -12px;
        width: 360px;
        height: 780px;
        position: absolute;
        border-radius: 40px;
        pointer-events: none;
        box-sizing: content-box;
        border: 12px solid #FFFFFF;
      }

      &__header-bg {
        top: 0;
        left: 0;
        width: 100%;
        position: absolute;
      }

      &__wrapper {
        width: 100%;
        min-height: 100%;
        border-radius: 28px;
        box-sizing: border-box;
        border: 1px solid #F2F5FC;
        background-color: #FFFFFF;

        .scroll {
          top: 0;
          left: -200px;
          position: absolute;
          border-radius: 28px;
          box-sizing: border-box;
          width: 560px !important;
          height: 780px !important;
          padding: 0 0 0 200px;

          .absolute {
            width: 360px !important;
            padding: 35px 20px 35px 20px;
          }
        }
      }

      .q-scrollarea__bar, .q-scrollarea__thumb {
        width: 0;
      }

      &__add-button {
        padding: 0;
        bottom: 12px;
        position: absolute;
        border-radius: 50%;
        left: calc(50% - 22px);
      }
    }

    &__sidebar {
      width: 100%;
      height: 100%;
      max-width: 320px;
      justify-self: end;
    }

    &__block-types-dialog {
      display: grid;
      row-gap: 10px;
      grid-template-columns: 1fr;
    }
  }

</style>
