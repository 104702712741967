var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-editing-page"},[_c('page-toolbar'),_c('div',{staticClass:"page-editing-page__wrapper"},[_c('q-space'),_c('div',{staticClass:"page-editing-page__phone-area"},[_c('div',{staticClass:"page-editing-page__phone"},[_c('q-scroll-area',{staticClass:"page-editing-page__phone__wrapper"},[(_vm.item)?[(_vm.item.design)?_c('div',{staticClass:"page-editing-page__phone__header-bg",style:({
                height: _vm.headerBgHeight,
                background: _vm.item.design.headerBg,
              })}):_vm._e(),_vm._l((_vm.item.items),function(block,index){return _c('page-block',{key:index,ref:"blocks",refInFor:true,attrs:{"value":block,"first":index === 0,"design":_vm.item.design,"active":index === _vm.activeItemIndex,"last":index === _vm.item.items.length - 1},on:{"moveUp":function($event){return _vm.moveBlockUp(index)},"moveDown":function($event){return _vm.moveBlockDown(index)},"select":function () { return _vm.activeItemIndex = index; },"remove":function($event){return _vm.$refs.blockRemoveDialog.show(
              { index: index, name: block.title },
              'Удаление блока'
            )}}})})]:_vm._e()],2),_c('q-btn',{staticClass:"page-editing-page__phone__add-button",attrs:{"round":"","color":"primary","icon":"eva-plus"},on:{"click":function($event){return _vm.$refs.addButtonDialog.show({}, 'Выберите объект')}}})],1)]),(_vm.activeItemIndex !== null)?_c('q-card',{staticClass:"page-editing-page__sidebar",attrs:{"flat":""}},[(_vm.item.items[_vm.activeItemIndex])?_c('page-block-editor',{attrs:{"value":_vm.item.items[_vm.activeItemIndex]},on:{"input":_vm.input}}):_vm._e()],1):_vm._e()],1),_c('ui-dialog',{ref:"addButtonDialog",attrs:{"width":460}},[_c('div',{staticClass:"page-editing-page__block-types-dialog"},_vm._l((_vm.blockTypes),function(type){return _c('page-block-type-button',{key:type.id,attrs:{"value":type},on:{"click":_vm.addBlock}})}),1),_c('div',{attrs:{"slot":"actions"},slot:"actions"})]),_c('ui-dialog',{ref:"blockRemoveDialog",attrs:{"width":520},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var item = ref.item;
return [_vm._v(" Вы уверены что хотите безвозвратно удалить блок"+_vm._s(item.name ? ' ' + item.name : '')+"? "),_c('q-btn',{staticClass:"q-mt-lg full-width",attrs:{"no-caps":"","unelevated":"","color":"red"},on:{"click":function($event){return _vm.removeBlock(item.index)}}},[_vm._v(" Удалить блок ")])]}}])},[_c('div',{attrs:{"slot":"actions"},slot:"actions"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }