<template>

  <q-input
    filled
    :type="type"
    :value="value"
    :label="label"
    :rules="rules"
    class="ui-input"
    :disable="disable"
    @input="(v) => $emit('input', v)">

    <!-- Set Scoped Slots From Parent -->
    <template
      :slot="slot"
      slot-scope="scope"
      v-for="slot in Object.keys($scopedSlots)">

      <slot :name="slot" v-bind="scope"/>

    </template>

  </q-input>

</template>

<script>
export default {
  name: 'ui-input',
  components: {},
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">

  .ui-input {

    .q-field__control {
      padding: 0 20px;
      overflow: hidden;
      background: #F2F5FC;
      border-radius: 12px;
      border: none !important;

      &:before {
        opacity: 0!important;
      }
    }

    &.q-textarea {

      textarea { padding: 0 18px; }

      .q-field__label { padding: 0 20px; }
      .q-field__control { padding: 0 2px; }

      .q-textarea.q-field--labeled .q-field__control-container {
        padding-top: 17px;
      }
    }
  }

</style>
