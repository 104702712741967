import Client from '../../plugins/graphql-client';

export async function UPLOAD(context, {
  name,
  file,
}) {
  const actionArgument = {
    name: 'createFile',
    file,
    args: {
      name,
      file: '$file',
    },
    fields: [
      'id',
      'path',
      'type',
      'server',
      {
        name: 'owner',
        fields: ['id', 'name'],
      },
    ],
  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}
