<template>

  <q-header class="layout-header">

    <q-toolbar class="layout-header__wrapper">

      <h3 class="layout-header__title">
        {{ title }}
      </h3>

      <q-space/>

      <q-badge
        v-if="badge"
        color="primary"
        class="q-mr-lg text-caption">

        <q-icon
          class="q-mr-xs"
          name="eva-star-outline"/>

        {{ badge }}

      </q-badge>

      <q-img
        ratio="1/1"
        v-if="imageLink"
        :src="imageLink"
        no-default-spinner
        class="layout-header__userpic"/>

      <div
        @click="$router.push({ name: 'profile' })"
        class="layout-header__name cursor-pointer">
        {{ name | cut(30) }}
      </div>

      <q-btn flat class="layout-header__chevron-button">

        <q-icon name="eva-chevron-down-outline" class="layout-header__chevron"/>

        <q-menu>

          <q-item clickable @click="$router.push({ name: 'profile' })">
            <q-item-section>Профиль</q-item-section>
          </q-item>

          <q-item clickable @click="$router.push({ name: 'logout' })">
            <q-item-section>Выход</q-item-section>
          </q-item>

        </q-menu>

      </q-btn>

    </q-toolbar>

  </q-header>

</template>

<script>
export default {
  name: 'layout-header',
  components: {

  },
  props: {
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    badge: {
      type: String,
      default: '',
    },
    imageLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss">

  .layout-header {
    background: unset;

    &__wrapper {
      height: 100px;
      padding: 0 32px;
    }

    &__userpic {
      width: 37px;
      height: 37px;
      margin-right: 12px;
      border-radius: 50%;
      overflow: auto !important;
      border: 2px solid $primary;
    }

    &__title {

    }

    &__name {
      color: #8E94A3;
    }

    &__chevron {
      color: #8E94A3;

      &-button {

        .q-btn__wrapper {
          padding: 0 0 0 10px;
        }
      }
    }
  }

</style>
