import Client from '../../plugins/graphql-client';

export async function INIT(context, {
  tariff,
  months,
}) {
  const actionArgument = {
    name: 'initPayment',
    args: {
      tariff,
      months,
    },
  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}

export async function CANCEL() {
  const actionArgument = {
    name: 'cancelSubscription',
  };

  const client = Client();
  const response = await client.mutation(actionArgument);

  return response || null;
}
