<template>

  <div class="finance-page layout__wrapper">

    <div class="finance-page__widgets">

      <q-card flat class="finance-page__widget">

        Тариф

        <h2>

          {{ subscribeTo ? tariff.name : 'Free' }}

          <div class="text-caption text-secondary">
            {{ subscribeTo || 'Бессрочно' }}
          </div>

        </h2>

        <q-btn
          label="Сменить тариф"
          class="button-secondary full-width"
          @click="$refs.upgradingDialog.show({}, 'Сменить тариф')"/>

      </q-card>

      <q-card flat class="finance-page__widget">

        <div v-if="tariff.name === 'Free'">Купить Standard</div>
        <div v-else>Продлить подписку</div>

        <ui-select
          emit-value
          map-options
          label="Период"
          :items="longs"
          class="full-width"
          v-model="selectedLong">

          <template v-slot:option="scope">
            <finance-period-item :scope="scope"/>
          </template>

        </ui-select>

        <q-btn
          color="primary"
          @click="prolong"
          class="button-primary full-width"
          :label="tariff.name === 'Free' ? 'Оплатить' : 'Продлить'"/>

      </q-card>

      <q-card
        flat
        class="finance-page__widget"
        v-if="tariff.name !== 'Business'">

        <div>
          Улучшить до <span class="text-bold">business</span>
        </div>

        <div class="text-caption">
          – Неограничено визиток и страниц<br/>
          – Возможность добавить свой домен<br/>
          – Возможность скрыть лейбл "bicard"
        </div>

        <q-btn
          label="Улучшить"
          class="button-secondary full-width"
          @click="$refs.upgradingDialog.show({}, 'Улучшить тариф')"/>

      </q-card>

    </div>

    <q-card flat class="finance-page__list">

      Список транзакций

      <q-table
        :data="items"
        :columns="columns"/>

    </q-card>

    <!-- Upgrading Dialog -->
    <ui-dialog
      :width="480"
      ref="upgradingDialog">

      <ui-select
        emit-value
        map-options
        label="Тариф"
        :items="tariffsList"
        v-model="selectedTariff"
        class="full-width q-mb-md"/>

      <div
        :key="i"
        class="text-body2"
        v-for="(itm, i) in tariffs[selectedTariff].items">
        – {{ itm }}
      </div>

      <ui-select
        emit-value
        map-options
        label="Период"
        :items="currentLongs"
        v-model="selectedLong"
        class="full-width q-mt-md"
        v-if="selectedTariff !== 'free'">

        <template v-slot:option="scope">
          <finance-period-item :scope="scope"/>
        </template>

      </ui-select>

      <q-btn
        no-caps
        unelevated
        slot="actions"
        color="primary"
        @click="extend"
        class="q-mt-lg full-width"
        v-if="selectedTariff !== 'free'">
        Перейти к оплате
      </q-btn>

      <q-btn
        v-else
        no-caps
        unelevated
        slot="actions"
        color="primary"
        @click="cancel"
        class="q-mt-lg full-width">
        Отменить подписку
      </q-btn>

    </ui-dialog>

  </div>

</template>

<script>
import moment from 'moment';
import map from 'lodash/map';
import { mapState } from 'vuex';
import orderBy from 'lodash/orderBy';
import { formatMoney } from '../utils';
import statusesCollection from '../collections/payment-statuses-collection.json';
import FinancePeriodItem from '../components/finance/finance-period-item';
import tariffsCollection from '../collections/tariffs-collection.json';
import UiSelect from '../components/ui/ui-select';
import UiDialog from '../components/ui/ui-dialog';

export default {
  name: 'finance-page',
  components: {
    UiDialog,
    UiSelect,
    FinancePeriodItem,
  },
  data() {
    return {
      months: 1,
      selectedLong: 1,
      selectedTariff: 'business',
      long: [1, 3, 6, 12, 24, 36],
      columns: [
        {
          name: 'updatedAt',
          field: 'updatedAt',
          label: 'Дата',
          align: 'left',
          format: (v) => (v ? moment(+v).format('D.MM.YYYY H:mm:ss') : ''),
        },
        {
          name: 'status',
          field: 'status',
          label: 'Статус',
          align: 'center',
          format: (v) => statusesCollection[v] || statusesCollection.DEFAULT,
        },
        {
          name: 'pan',
          field: 'pan',
          label: 'Карта',
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'Сумма',
          format: (v) => +v / 100,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      payments: (state) => state.payments.items,
      user: (state) => state.users.sessionItem,
    }),
    items() {
      return orderBy(this.payments, [(o) => +o.updatedAt], ['desc']);
    },
    tariff() {
      return tariffsCollection[this.user && this.user.subscribeType ? this.user.subscribeType : 'free'];
    },
    tariffs() {
      return tariffsCollection;
    },
    tariffsList() {
      return map(tariffsCollection, (itm) => ({
        label: itm.name,
        value: itm.name.toLowerCase(),
      }));
    },
    subscribeTo() {
      if (!this.user) return '';
      if (!this.user.subscribeTo) return '';
      if (this.tariff.name === 'Free') return '';
      if (moment().valueOf() > this.user.subscribeTo) return '';

      let date = moment(+this.user.subscribeTo);
      if (!date.isValid()) return '';

      return `Активен до ${date.format('DD.MM.YYYY')}`;
    },
    longs() {
      return this.long.map((v) => {
        const pricing = (this.tariff.price || this.tariffs.standard.price);
        const oldPrice = v > 1 ? pricing * v : '';
        const price = v > 1 ? (pricing * v) - ((pricing * v) * (v / 100)) : pricing;

        return {
          price,
          oldPrice,
          value: v,
          month: `${v} мес.`,
          label: `${v} мес. (${formatMoney(price, undefined, 0)})`,
        };
      });
    },
    currentLongs() {
      const tariff = tariffsCollection[this.selectedTariff];

      return this.long.map((v) => {
        const pricing = (tariff.price || this.tariffs.standard.price);
        const oldPrice = v > 1 ? pricing * v : '';
        const price = v > 1 ? (pricing * v) - ((pricing * v) * (v / 100)) : pricing;

        return {
          price,
          oldPrice,
          value: v,
          month: `${v} мес.`,
          label: `${v} мес. (${formatMoney(price, undefined, 0)})`,
        };
      });
    },
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);

    this.$store.commit('payments/SET_PAGINATION', {
      limit: 999,
    });

    this.$store.dispatch('payments/FETCH_ITEMS', {
      fields: [
        'id',
        'pan',
        'status',
        'amount',
        'updatedAt',
      ],
    });
  },
  methods: {
    prolong() { // Продлить
      const tariff = this.tariff.name.toLowerCase();

      this.$store.dispatch('payments/INIT', {
        months: this.selectedLong,
        tariff: tariff === 'free' ? 'standard' : tariff,
      }).then((url) => {
        if (url) window.open(url, '_blank').focus();
      }).catch((e) => {
        console.error(e);
        this.$error('Не удалось выполнить запрос');
      });
    },
    extend() { // Улучшить
      this.$store.dispatch('payments/INIT', {
        months: this.selectedLong,
        tariff: this.selectedTariff,
      }).then((url) => {
        if (url) window.open(url, '_blank').focus();

        this.$refs.upgradingDialog.hide();
      }).catch((e) => {
        console.error(e);
        this.$error('Не удалось выполнить запрос');
      });
    },
    cancel() {
      this.$store.dispatch('payments/CANCEL').then((result) => {
        if (result) this.$success('Подписка отменена. Текущий тариф будет действовать до конца периода.');
        else this.$error('Не удалось отменить подписку');

        this.$refs.upgradingDialog.hide();
      }).catch((e) => {
        console.error(e);
        this.$error('Не удалось выполнить запрос');
      });
    },
  },
};
</script>

<style lang="scss">

  .finance-page {

    &__widgets {
      display: grid;
      grid-gap: 32px;
      margin-bottom: 32px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__widget {
      display: grid;
      grid-gap: 16px;
      align-items: center;
      grid-template-rows: 20px 56px 56px;
    }
  }

</style>
