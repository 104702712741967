import Vue from 'vue';
import Vuex from 'vuex';
import Notify from 'quasar/src/plugins/Notify.js';;
import Client from '../plugins/graphql-client';

import createEventsModule from './events';
import createGlobalModule from './global';
import createFilesModule from './files';
import createCardsModule from './cards';
import createPagesModule from './pages';
import createUsersModule from './users';
import createPaymentsModule from './payments';

Vue.use(Vuex);

export default function CreateStore() {
  const onSuccess = ({ action }) => {
    if (action === 'FETCH_ITEM' || action === 'FETCH_ITEMS') return;

    Notify.create({
      message: 'Операция выполнена.',
      color: 'positive',
    });
  };

  const onError = ({ error }) => Notify.create({
    message: error.message || 'Неизвестная ошибка',
    color: 'negative',
  });

  const client = new Client();

  const options = {
    client,
    onError,
    onSuccess,
  };

  return new Vuex.Store({
    modules: {
      events: {
        namespaced: true,
        ...createEventsModule(options),
      },
      global: {
        namespaced: true,
        ...createGlobalModule(options),
      },
      files: {
        namespaced: true,
        ...createFilesModule(options),
      },
      cards: {
        namespaced: true,
        ...createCardsModule(options),
      },
      pages: {
        namespaced: true,
        ...createPagesModule(options),
      },
      users: {
        namespaced: true,
        ...createUsersModule(options),
      },
      payments: {
        namespaced: true,
        ...createPaymentsModule(options),
      },
    },
  });
}
