<template>

  <div class="ui-color">

    <div
      class="ui-color__tile"
      :style="{
        backgroundColor: value || '#FFFFFF',
        border: (value === '#FFFFFF' || value === '#ffffff') ? '1px solid #DBE0EE' : 'none',
      }">

      <q-popup-proxy
        transition-show="scale"
        transition-hide="scale"
        :value="colorPickerDisplayed"
        @input="v => colorPickerDisplayed = v">

        <q-color
          :value="value"
          @input="v => $emit('input', v)"/>

      </q-popup-proxy>

    </div>

    <ui-input
      :label="label"
      :value="value"
      @input="v => $emit('input', v)">

      <template v-slot:append>

        <q-icon
          class="cursor-pointer"
          name="eva-color-palette-outline"
          @click="() => colorPickerDisplayed = true"/>

      </template>

    </ui-input>

  </div>

</template>

<script>
import UiInput from './ui-input';

export default {
  name: 'ui-color',
  components: {
    UiInput,
  },
  props: {
    label: {
      type: String,
      default: 'Цвет',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colorPickerDisplayed: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">

  .ui-color {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 56px 1fr;

    &__tile {
      cursor: pointer;
      border-radius: 12px;
    }
  }

</style>
