<template>

  <div class="auth-page dialog-layout__wrapper">

    <div class="dialog-layout__description">

      <div class="dialog-layout__description__logo">

        <q-img
          width="127px"
          height="25px"
          src="/assets/img/logo-white.svg"/>

      </div>

      <div class="dialog-layout__description__text">

        <div class="text-h3 text-white text-bold q-mb-sm">
          Вход
        </div>

        <div class="text-body1 text-white">
          Создавайте стильные
          <span class="text-no-wrap">онлайн-визитки </span>
          <span class="text-no-wrap">с QR-кодом, </span>
          ведущим на вашу страничку
        </div>

      </div>

      <div class="dialog-layout__description__actions">

        <div style="color: #C5DAFF;">
          Ещё нет аккаунта?
        </div>

        <q-btn
          flat
          no-caps
          color="white"
          class="no-padding"
          :to="{name: 'dialog-signup'}">
          Зарегистрироваться
        </q-btn>

      </div>

    </div>

    <!-- Form -->
    <q-form
      autofocus
      @submit="submit"
      class="dialog-layout__form">

      <div v-if="!done">

        <!-- Errors Banner -->
        <q-banner
          dense
          v-if="errors.length"
          class="text-white bg-red q-mb-md">

          <div
            :key="index"
            v-for="({ message }, index) in errors">
            {{ message }}
          </div>

        </q-banner>

        <div
          v-else
          class="q-mb-lg text-body1">
          Укажите e-mail, который вы использовали для регистрации
        </div>

        <!-- Text Field - Login -->
        <ui-input
          label="E-mail"
          class="q-mb-lg"
          v-model="email"/>

      </div>

       <div v-else>
         Ссылка для восстановления пароля отправлена на указанный e-mail.
         Перейдите по этой ссылке, чтобы задать новый пароль!
       </div>

      <!-- Button Submit -->
      <q-btn
        no-caps
        size="lg"
        v-if="!done"
        type="submit"
        color="primary"
        :loading="loading"
        class="full-width button-primary">
        Сбросить пароль
      </q-btn>

    </q-form>

  </div>

</template>

<script>
import UiInput from '../../components/ui/ui-input';

export default {
  name: 'reset-page',
  components: { UiInput },
  props: {},
  data() {
    return {
      email: '',
      errors: [],
      done: false,
      loading: false,
    };
  },
  methods: {
    submit() {
      this.errors = [];

      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!reg.test(String(this.email).toLowerCase())) this.errors.push({
        message: 'Некорректный e-mail',
      });

      if (this.errors.length) return;

      this.loading = true;

      this.$store.dispatch('users/PASS_RESET', {
        email: this.email,
        pass: this.pass,
      }).then(() => {
        this.done = true;
      }).catch((errors) => {
        this.loading = false;
        this.errors = errors;
      });
    },
  },
};
</script>
