<template>

  <div class="ui-checkbox">

    <q-checkbox
      :value="value"
      @input="(v) => $emit('input', v)"/>

    <div class="ui-checkbox__label">

      <slot>
        <span>{{ label }}</span>
      </slot>

    </div>

  </div>

</template>

<script>
export default {
  name: 'ui-checkbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style lang="scss">

  .ui-checkbox {
    display: grid !important;
    grid-gap: 10px;
    grid-template-columns: auto 1fr;

    &__label {
      color: #8E94A3;

      a {
        color: #2754c7;
        text-decoration: none;
      }
    }
  }

  // Overrides
  .q-checkbox {
    align-items: flex-start;

    &__bg {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 8px;
      background: #F2F5FC;

      svg { padding: 4px; }
    }

    &__inner {
      font-size: 26px;
      width: 1em;
      min-width: 1em;
      height: 1em;
      outline: 0;
      border-radius: 50%;
    }
  }

</style>
