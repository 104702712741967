<template>

  <q-layout view="lHh Lpr lFf" class="bg-grey-11">

    <q-page-container>

      <router-view/>

    </q-page-container>

  </q-layout>

</template>

<script>
export default {
  name: 'LayoutDefault',
  data() {
    return {
    };
  },
  computed: {
    externalRoute() {
      const routeName = this.$route.name;

      if (routeName === 'dialog-login') return true;
      if (routeName === 'dialog-reset') return true;
      if (routeName === 'dialog-signup') return true;

      return routeName === 'error';
    },
  },
  created() {
    this.$q.screen.setSizes({
      xs: 300,
      sm: 500,
      md: 900,
      lg: 1200,
    });
  },
};
</script>
