import Vue from 'vue';
import '../styles/quasar.scss';
import lang from 'quasar/lang/ru';
import '@quasar/extras/eva-icons/eva-icons.css';
import iconSet from 'quasar/icon-set/eva-icons';
import Notify from 'quasar/src/plugins/Notify.js';import Quasar from 'quasar/src/vue-plugin.js';import Cookies from 'quasar/src/plugins/Cookies.js';;
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';

Vue.use(Quasar, {
  lang,
  iconSet,
  config: {
    notify: {
      html: true,
      timeout: 5000,
      position: 'bottom-right',
    },
    loadingBar: {
      color: 'accent',
    },
  },
  plugins: [
    Notify,
    Cookies,
  ],
});
