<template>

  <div class="card-editing-page">

    <card-toolbar/>

    <div class="card-editing-page__wrapper">

      <!-- Templates List -->
      <q-card
        flat
        style="padding: 0"
        class="card-editing-page__templates">

        <q-scroll-area>

          <card-design-card
            :key="i"
            :value="design"
            @click="setDesign(design)"
            v-for="(design, i) in designsList"/>

        </q-scroll-area>

      </q-card>

      <!-- Viewer -->
      <card-viewer :value="item"/>

      <!-- Properties Editor -->
      <q-card
        flat
        style="padding: 0"
        class="card-editing-page__sidebar">

        <card-design-editor
          v-if="item"
          :value="item.data || {}"
          @input="data => $store.commit('cards/INPUT', { ...this.item, data } )"/>

      </q-card>

    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import CardDesignsCollection from '../collections/card-designs-collection.json';
import CardDesignCard from '../components/cards/card-design-card';
import CardToolbar from '../components/cards/card-toolbar';
import CardViewer from '../components/cards/card-viewer';
import CardDesignEditor from '../components/cards/card-design-editor';

export default {
  name: 'card-editing-page',
  components: {
    CardDesignEditor,
    CardViewer,
    CardToolbar,
    CardDesignCard,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      designType: 'all',
    };
  },
  computed: {
    ...mapGetters('cards', {
      item: 'value',
    }),
    designsList() {
      const type = this.designType;
      return type === 'all'
        ? [
          ...CardDesignsCollection.colors,
        ]
        : CardDesignsCollection[type];
    },
  },
  created() {
    this.$store.commit('cards/RESET_ITEM');
    this.$store.dispatch('cards/FETCH_ITEM', {
      args: { id: this.id },
      fields: [
        'id',
        'name',
        {
          name: 'page',
          fields: ['id', 'slug'],
        },
        {
          name: 'data',
          fields: [
            'logoX',
            'logoY',
            'title',
            'titleX',
            'titleY',
            'subtitle',
            'subtitleX',
            'subtitleY',
            'color',
            'background',
            'backLogoX',
            'backLogoY',
            'backTitle',
            'backTitleX',
            'backTitleY',
            'backSubtitle',
            'backSubtitleX',
            'backSubtitleY',
            'backColor',
            'backBackground',
            {
              name: 'logo',
              fields: ['path', 'server'],
            },
          ],
        },
      ],
    });
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);
  },
  methods: {
    setDesign(design) {
      const data = {
        ...this.item.data || {},
        ...design,
      };

      this.$store.commit('cards/INPUT', { ...this.item, data });
    },
  },
};
</script>

<style lang="scss">

  .card-editing-page {

    padding: 0 32px;

    &__wrapper {
      display: grid;
      grid-gap: 32px;
      min-height: calc(100vh - 200px);
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__templates {
      width: 100%;
      height: 100%;
      overflow: hidden;
      max-width: 320px;
      justify-self: start;

      .q-scrollarea {
        width: 100%;
        height: 100%;

        .absolute {
          padding: 32px;
        }
      }
    }

    &__sidebar {
      width: 100%;
      height: 100%;
      max-width: 320px;
      justify-self: end;
    }
  }

</style>
