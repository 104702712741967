import Cookies from 'quasar/src/plugins/Cookies.js';;

export function SET_USER(state, value) {
  state.sessionItem = value || null;
}

export function SET_SESSION_ITEM(state, value) {
  if (!value || !value.token) throw Error('unexpected session item');

  state.sessionItem = value.user || null;
  Cookies.set('accessToken', value.token, {
    expires: 2147483647,
    path: '/',
  });
}

export function RESET_SESSION_ITEM(state) {
  state.sessionItem = null;
  Cookies.remove('accessToken');
}
