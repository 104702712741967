<template>

  <div class="card-download-page">

    <card-toolbar/>

    <div class="card-download-page__wrapper">

      <!-- Viewer -->
      <card-viewer
        inline
        ref="viewer"
        :value="item"/>

      <q-card
        flat
        style="padding: 0"
        class="card-download-page__buttons">

        <q-btn
          no-caps
          icon="fas fa-download"
          label="Скачать в формате SVG"
          @click="$refs.viewer.download()"/>

      </q-card>

    </div>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import CardToolbar from '../components/cards/card-toolbar';
import CardViewer from '../components/cards/card-viewer';

export default {
  name: 'card-download-page',
  components: {
    CardViewer,
    CardToolbar,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('cards', {
      item: 'value',
    }),
  },
  created() {
    this.$store.commit('cards/RESET_ITEM');
    this.$store.dispatch('cards/FETCH_ITEM', {
      args: { id: this.id },
      fields: [
        'id',
        'name',
        {
          name: 'page',
          fields: ['id', 'slug'],
        },
        {
          name: 'data',
          fields: [
            'logoX',
            'logoY',
            'title',
            'titleX',
            'titleY',
            'subtitle',
            'subtitleX',
            'subtitleY',
            'color',
            'background',
            'backLogoX',
            'backLogoY',
            'backTitle',
            'backTitleX',
            'backTitleY',
            'backSubtitle',
            'backSubtitleX',
            'backSubtitleY',
            'backColor',
            'backBackground',
            {
              name: 'logo',
              fields: ['path', 'server'],
            },
          ],
        },
      ],
    });
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);
  },
};
</script>

<style lang="scss">

  .card-download-page {
    padding: 0 32px;

    &__wrapper {
      display: grid;
      grid-gap: 32px;
      grid-template-rows: auto 120px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      .q-btn {
        border-radius: 16px;
        background-color: #F2F5FC;

        .q-icon {
          color: $primary;
        }
      }
    }
  }

</style>
