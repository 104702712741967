var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout__wrapper"},[_c('div',{staticClass:"pages-page"},[_c('q-card',{staticClass:"cursor-pointer pages-page__card-add",attrs:{"flat":""},on:{"click":function($event){_vm.items.length < _vm.tariff.pages
        ? _vm.$refs.creatingDialog.show({}, 'Создание страницы')
        : _vm.$error('На вашем тарифе доступно не более ' + _vm.tariff.pages + ' страниц')}}},[_c('q-icon',{attrs:{"name":"eva-plus-outline"}}),_c('div',{staticClass:"text-weight-medium"},[_vm._v(" Создать страницу ")])],1),_vm._l((_vm.items),function(item,i){return _c('page-card',{key:i,attrs:{"slug":item.slug,"name":item.name || '',"description":item.description,"image-link":item.image ? _vm.getFileUrl(item.image) : ''},on:{"makeQR":function($event){return _vm.qrCodeShow(item.id)}}})})],2),_c('ui-dialog',{ref:"creatingDialog",attrs:{"width":520},on:{"submit":_vm.submitCreatingDialog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var input = ref.input;
        var item = ref.item;
return [_c('ui-input',{staticClass:"q-mb-md",attrs:{"label":"Название","value":item.title},on:{"input":function (v) { return input(Object.assign({}, item, {title: v})); }}}),_c('ui-input',{attrs:{"label":"Адрес","value":item.slug,"rules":[
          function (v) { return !!v || 'Поле обязательно для заполнения'; },
          function (v) { return (v.length > 3) || 'Адрес должен содержать не менее трёх символов'; },
          function (v) { return /^[a-z]+$/.test(v) || 'Доступны только маленькие латинские буквы'; } ]},on:{"input":function (v) { return input(Object.assign({}, item, {slug: v})); }}},[_c('div',{staticClass:"text-body1",attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v(" http://bicard.pro/ ")])])]}}])}),_c('ui-dialog',{ref:"qrCodeDialog",attrs:{"width":520},on:{"hide":_vm.qrCodeHide}},[_c('div',{attrs:{"id":"qrcode__wrapper"}},[_c('div',{attrs:{"id":"qrcode"}})]),_c('a',{staticClass:"q-mt-lg full-width",attrs:{"href":"","id":"qrcode__link","download":"qrcode.svg"}},[_vm._v(" Скачать в формате svg ")]),_c('q-btn',{staticClass:"q-mt-lg full-width",attrs:{"slot":"actions","no-caps":"","unelevated":"","color":"primary"},on:{"click":function($event){return _vm.$refs.qrCodeDialog.hide()}},slot:"actions"},[_vm._v(" OK ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }