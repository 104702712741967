<template>

  <div class="dialog-layout">

    <div class="dialog-layout__card">

      <router-view/>

    </div>

    <div class="dialog-layout__picture"/>

  </div>

</template>

<script>
export default {
  name: 'dialog-layout',
  created() {
  },
};
</script>

<style lang="scss">

  .dialog-layout {
    z-index: 1;
    height: 100vh;
    display: grid;
    position: relative;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: auto 346px 420px 346px auto;

    &__card {
      height: 560px;
      padding: 20px;
      position: relative;
      border-radius: 30px;
      grid-area: 2 / 2 / 3 / 4;
      background-color: #ffffff;

      &:before {
        content: "";
        top: 47px;
        left: 55px;
        right: 55px;
        bottom: 14px;
        z-index: -1;
        opacity: .1;
        filter: blur(50px);
        position: absolute;
        border-radius: 16px;
        background: linear-gradient(.37turn, #4388FF, #316BFF);
      }
    }

    &__picture {
      width: 100%;
      height: 100%;
      grid-area: 2 / 4 / 4 / 6;
      background-image: url("/assets/img/hand-hi.png");
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__wrapper {
      height: 100%;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 306px 1fr;
    }

    &__description {
      width: 100%;
      height: 100%;
      display: grid;
      padding: 30px;
      grid-gap: 50px;
      border-radius: 16px;
      grid-template-rows: auto 1fr auto;
      background: linear-gradient(.10turn, #316BFF, #4388FF);

      &__logo {
        line-height: 45px;
      }

      &__actions {
        border-radius: 16px;
        padding: 16px 26px 6px;
        background-color: rgba(255, 255, 255, .1);
      }
    }

    &__form {
      height: 100%;
      display: grid;
      padding: 30px 50px;
      grid-template-rows: 1fr auto;
    }

    .q-banner {
      padding: 8px 20px;
      border-radius: 16px;
    }

    .button-primary {
      color: #FFFFFF;
      z-index: 0;
      line-height: 48px;
      border-radius: 16px;
      font-size: 16px !important;
      background: linear-gradient(.57turn, #4388FF, #316BFF);

      &:before {
        content: "";
        top: 30px;
        left: 15px;
        right: 15px;
        bottom: 4px;
        opacity: .7;
        z-index: -1;
        filter: blur(35px);
        position: absolute;
        border-radius: 16px;
        background: linear-gradient(.57turn, #4388FF, #316BFF);
      }
    }
  }

</style>
