<template>

  <div
    id="faq"
    class="layout-faq">

    <h1 class="text-center">
      Вопросы <span class="text-primary">и ответы</span>
    </h1>

    <q-expansion-item
      :key="index"
      :label="item.label"
      v-for="(item, index) in items">

      <div
        v-html="item.text"
        class="layout-faq__content"/>

    </q-expansion-item>

  </div>

</template>

<script>
export default {
  name: 'layout-faq',
  props: {
    /**
     * List of objects
     * label - Title for expansion panel
     * text - Expanded content (html)
     */
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  .layout-faq {
    margin: 0 auto;
    max-width: 800px;

    h1 {
      margin-bottom: 60px;
    }

    .q-item {
      font-size: 17px;
      min-height: 70px;
      font-weight: 500;
      padding: 8px 48px;

      @media (max-width: $breakpoint-lg) {
        padding: 6px 28px;
      }
    }

    .q-intersection {
      z-index: 1;
    }

    .q-expansion-item {
      margin-bottom: 14px;
      border-radius: 16px;
      background-color: #FFFFFF;

      &:before {
        content: "";
        top: 36px;
        left: 30px;
        right: 30px;
        bottom: 20px;
        z-index: -1;
        opacity: .50;
        filter: blur(20px);
        position: absolute;
        border-radius: 16px;
        background: linear-gradient(.37turn, #4388FF, #316BFF);
      }
    }

    .q-icon {
      color: #316BFF;
    }

    .q-focus-helper {
      display: none;
    }

    &__content {
      font-size: 16px;
      font-weight: 400;
      padding: 12px 48px 24px;

      @media (max-width: $breakpoint-lg) {
        padding: 6px 28px 12px;
      }
    }
  }

</style>
