<template>

  <!-- Put Dialog -->
  <q-dialog
    ref="dialog"
    class="ui-dialog"
    :full-width="fullscreen"
    :full-height="fullscreen">

    <!-- Dialog Content -->
    <q-card
      :style="{width: width + 'px'}"
      class="ui-dialog__content shadow-0">

      <q-toolbar
        :class="[
          'no-shrink',
          'q-px-xs-sm',
          'q-py-xs-xs',
          'q-px-sm-md',
          'q-py-sm-sm',
          'text-center',
        ]">

        <q-toolbar-title
          v-if="title"
          class="ui-dialog__title">

          {{ title }}

        </q-toolbar-title>

        <slot name="toolbar"/>

      </q-toolbar>

      <q-btn
        flat
        round
        dense
        @click="hide"
        icon="eva-close"
        class="ui-dialog__button-close"/>

      <div>

        <q-form
          @submit="submit"
          class="q-mt-md q-mx-xl ui-dialog__content__form">

          <div>

            <slot
              v-if="item"
              :item="item"
              :input="input"/>

          </div>

          <slot name="actions">

            <div class="q-mt-lg column">

              <q-btn
                no-caps
                unelevated
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="loading"
                class="col-12 col-sm-4 q-mt-xs-sm q-mt-sm-none">
                {{ primaryActionText }}
              </q-btn>

              <q-btn
                flat
                light
                no-caps
                unelevated
                @click="hide"
                color="primary"
                :disabled="loading"
                class="col-12 col-sm-4">
                {{ secondaryActionText }}
              </q-btn>

            </div>

          </slot>

        </q-form>

      </div>

    </q-card>

  </q-dialog>

</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';

export default {
  name: 'ui-dialog',
  props: {
    width: {
      type: [String, Number],
      default: 650,
    },
    hideOnSubmit: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    primaryActionText: {
      type: String,
      default: 'Сохранить',
    },
    secondaryActionText: {
      type: String,
      default: 'Отмена',
    },
  },
  data() {
    return {
      item: null,
      loading: false,
      title: 'Элемент',
    };
  },
  methods: {
    show(item, title) {
      const itm = isObject(item)
        ? cloneDeep(item)
        : item;

      this.item = itm || this.item;
      this.title = title || this.title;

      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();

      this.item = null;
      this.loading = false;
      this.title = 'Элемент';

      this.$emit('hide');
    },
    submit() {
      this.$emit('submit', this.item);

      if (this.hideOnSubmit) this.hide();
    },
    input(value) {
      this.item = value;
    },
  },
};

</script>

<style lang="scss">

  .ui-dialog {

    &__title {
      font-weight: 600;
    }

    &__content {
      position: relative;
    }

    &__button-close {
      top: 18px;
      right: 18px;
      position: absolute;

      & i {
        color: #8E94A3;
        font-size: 28px !important;
      }
    }
  }

</style>
