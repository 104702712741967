<template>

  <q-btn
    :icon="value.icon"
    :label="value.label"
    class="page-block-type-button"
    @click="$emit('click', value.id)"/>

</template>

<script>
export default {
  name: 'page-block-type-button',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">

  .page-block-type-button.q-btn {
    border-radius: 12px;
    background-color: #F2F5FC;

    .q-btn__wrapper {
      padding: 0 10px;
    }

    .q-icon {
      color: $primary;
    }
  }

</style>
