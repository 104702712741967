<template>

  <div class="card-toolbar">

    <q-card flat>

      <q-tabs
        no-caps
        inline-label
        align="left"
        :value="routeName"
        indicator-color="primary">

        <q-tab
          label="Редактор"
          @click="toEdit()"
          name="card-editor"
          icon="eva-edit-outline"/>

        <q-tab
          label="Настройки"
          name="card-settings"
          @click="toSettings()"
          icon="eva-settings-2-outline"/>

        <q-tab
          label="Загрузка"
          @click="toDownload"
          name="card-download"
          icon="eva-download-outline"/>

      </q-tabs>

    </q-card>

    <div>

      <q-btn
        @click="save"
        label="Сохранить"
        :loading="loading"
        :disable="!changed"
        :color="changed ? 'positive' : 'secondary'"/>

      <q-tooltip
        v-if="!changed"
        :offset="[10, 10]"
        content-class="bg-info text-body2">
        Нет несохранённых изменений
      </q-tooltip>

    </div>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import isEqual from 'lodash/isEqual';

export default {
  name: 'card-toolbar',
  components: {},
  props: {},
  data() {
    return {
      tab: 'editing',
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    changed() {
      return !isEqual(
        this.item,
        this.tempItem,
      );
    },
    routeName() {
      return this.$route.name;
    },
    ...mapState({
      item: (state) => state.cards.item,
      loading: (state) => state.cards.loading,
      tempItem: (state) => state.cards.tempItem,
    }),
  },
  methods: {
    toEdit() {
      const { id } = this;
      const name = 'card-editor';

      if (this.routeName !== name) this.$router.push({ name, params: { id } });
    },
    toSettings() {
      const { id } = this;
      const name = 'card-settings';

      if (this.routeName !== name) this.$router.push({ name, params: { id } });
    },
    toDownload() {
      const { id } = this;
      const name = 'card-download';

      if (this.routeName !== name) this.$router.push({ name, params: { id } });
    },
    save() {
      this.$store.commit('cards/SET_LOADING', true);

      this.$store.dispatch('cards/UPDATE_ITEM', {
        args: {
          ...this.tempItem,
          page: this.tempItem.page && this.tempItem.page.id
            ? { id: this.tempItem.page.id }
            : {},
        },
        fields: [
          'id',
          'name',
          {
            name: 'page',
            fields: ['id'],
          },
          {
            name: 'data',
            fields: [
              'logoX',
              'logoY',
              'title',
              'titleX',
              'titleY',
              'subtitle',
              'subtitleX',
              'subtitleY',
              'color',
              'background',
              'backLogoX',
              'backLogoY',
              'backTitle',
              'backTitleX',
              'backTitleY',
              'backSubtitle',
              'backSubtitleX',
              'backSubtitleY',
              'backColor',
              'backBackground',
              {
                name: 'logo',
                fields: ['path', 'server'],
              },
            ],
          },
        ],
      }).then(() => {
        this.$success('Визитка сохранена');
        this.$store.commit('cards/RESET_LOADING');
      }).catch((e) => {
        this.$error(e);
        this.$store.commit('cards/RESET_LOADING');
      });
    },
  },
};
</script>

<style lang="scss">

  .card-toolbar {
    height: 56px;
    display: grid;
    grid-gap: 16px;
    margin-bottom: 32px;
    grid-template-columns: 1fr auto;

    .q-card {
      padding: 0;
      border-radius: 12px;
    }

    .q-tab {
      height: 56px;
      padding: 0 45px;
      line-height: 56px;

      &__label {
        padding-left: 12px;
      }
    }

    .q-btn {
      border-radius: 12px;
    }

    .q-icon {
      color: $primary;
      font-size: 24px;
    }
  }

</style>
