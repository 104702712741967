<template>

  <div class="login-page dialog-layout__wrapper">

    <div class="dialog-layout__description">

      <div class="dialog-layout__description__logo">

        <q-img
          width="127px"
          height="25px"
          src="/assets/img/logo-white.svg"/>

      </div>

      <div class="dialog-layout__description__text">

        <div class="text-h3 text-white text-bold q-mb-sm">
          Регистрация
        </div>

        <div class="text-body1 text-white">
          Создавайте стильные
          <span class="text-no-wrap">онлайн-визитки </span>
          <span class="text-no-wrap">с QR-кодом, </span>
          ведущим на вашу страничку
        </div>

      </div>

      <div class="dialog-layout__description__actions">

        <div style="color: #C5DAFF;">
          Уже есть аккаунт?
        </div>

        <q-btn
          flat
          no-caps
          color="white"
          class="no-padding"
          :to="{name: 'dialog-login'}">
          Войти
        </q-btn>

      </div>

    </div>

    <!-- Signup Form -->
    <q-form
      autofocus
      @submit="submit"
      class="dialog-layout__form">

      <div>

        <!-- Errors Banner -->
        <q-banner
          dense
          v-if="errors.length"
          class="text-white bg-red q-mb-md">

          <div
            :key="index"
            v-for="({ message }, index) in errors">
            {{ message }}
          </div>

        </q-banner>

        <!-- Text Field - Name -->
        <ui-input
          label="Имя"
          class="q-mb-md"
          v-model="name"/>

        <!-- Text Field - Login -->
        <ui-input
          outlined
          label="E-mail"
          class="q-mb-md"
          v-model="email"/>

        <!-- Text Field - Password -->
        <ui-input
          label="Пароль"
          v-model="pass"
          class="q-mb-md"
          :type="passDisplayed ? 'text' : 'password'">

          <!-- Icon Show -->
          <template v-slot:append>

            <q-icon
              size="xs"
              class="cursor-pointer"
              @click="passDisplayed = !passDisplayed"
              :name="passDisplayed ? 'fa fa-eye' : 'fa fa-eye-slash'"/>

          </template>

        </ui-input>

        <ui-checkbox
          v-model="accept">
          <span class="text-caption">
            Согласен с условиями <a href="http://bicard.pro/privacy">пользовательского соглашения</a>
            и даю согласие на обработку персональных данных
          </span>
        </ui-checkbox>

      </div>

      <!-- Button Submit -->
      <q-btn
        no-caps
        size="lg"
        type="submit"
        color="primary"
        :loading="loading"
        class="full-width button-primary">
        Далее
      </q-btn>

    </q-form>

  </div>

</template>

<script>
import UiCheckbox from '../../components/ui/ui-checkbox';
import UiInput from '../../components/ui/ui-input';
import captcha from '../../mixins/captcha';

export default {
  name: 'signup-page',
  mixins: [captcha],
  components: {
    UiCheckbox,
    UiInput,
  },
  data() {
    return {
      name: '',
      pass: '',
      email: '',
      errors: [],
      accept: false,
      loading: false,
      passDisplayed: false,
    };
  },
  beforeMount() {
    this.captchaInit(process.env.VUE_APP_RECAPTCHA_TOKEN);
  },
  methods: {
    async submit() {
      this.errors = [];

      if (!this.name || !this.pass || !this.email) this.errors.push({
        message: 'Необходимо заполнить все поля',
      });

      if (this.errors.length) return;

      const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!reg.test(String(this.email).toLowerCase())) this.errors.push({
        message: 'Некорректный e-mail',
      });

      if (this.pass.length < 4) this.errors.push({
        message: 'Слишком короткий пароль',
      });

      if (this.errors.length) return;

      if (!this.accept) this.errors.push({
        message: 'Необходимо согласие на обработку персональных данных',
      });

      if (this.errors.length) return;

      this.loading = true;

      const recaptchaToken = await this.captchaExecute({ action: 'submit' });

      this.$store.dispatch('users/SIGN_UP', {
        recaptchaToken,
        name: this.name,
        pass: this.pass,
        email: this.email,
      }).then((/* result */) => {
        // console.log(result);
        this.$router.push({ name: 'main' });
      }).catch((errors) => {
        this.errors = errors;
        this.loading = false;
      });
    },
  },
};
</script>
