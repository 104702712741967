<template>

  <div class="layout__wrapper">

    <div class="pages-page">

      <!-- Add Button -->
      <q-card
        flat
        class="cursor-pointer pages-page__card-add"
        @click="items.length < tariff.pages
          ? $refs.creatingDialog.show({}, 'Создание страницы')
          : $error('На вашем тарифе доступно не более ' + tariff.pages + ' страниц')">

        <q-icon name="eva-plus-outline"/>

        <div class="text-weight-medium">
          Создать страницу
        </div>

      </q-card>

      <!-- Items -->
      <page-card
        :key="i"
        :slug="item.slug"
        :name="item.name || ''"
        v-for="(item, i) in items"
        @makeQR="qrCodeShow(item.id)"
        :description="item.description"
        :image-link="item.image ? getFileUrl(item.image) : ''"/>

    </div>

    <!-- Creating Dialog -->
    <ui-dialog
      :width="520"
      ref="creatingDialog"
      @submit="submitCreatingDialog">

      <template
        slot-scope="{input, item}">

        <ui-input
          label="Название"
          :value="item.title"
          class="q-mb-md"
          @input="v => input({...item, title: v})"/>

        <ui-input
          label="Адрес"
          :value="item.slug"
          @input="v => input({...item, slug: v})"
          :rules="[
            v => !!v || 'Поле обязательно для заполнения',
            v => (v.length > 3) || 'Адрес должен содержать не менее трёх символов',
            v => /^[a-z]+$/.test(v) || 'Доступны только маленькие латинские буквы',
          ]">

          <div slot="prepend" class="text-body1">
            http://bicard.pro/
          </div>

        </ui-input>

      </template>

    </ui-dialog>

    <!-- QR Code Dialog -->
    <ui-dialog
      :width="520"
      ref="qrCodeDialog"
      @hide="qrCodeHide">

      <div id="qrcode__wrapper">
        <div id="qrcode"/>
      </div>

      <a
        href=""
        id="qrcode__link"
        download="qrcode.svg"
        class="q-mt-lg full-width">
        Скачать в формате svg
      </a>

      <q-btn
        no-caps
        unelevated
        slot="actions"
        color="primary"
        class="q-mt-lg full-width"
        @click="$refs.qrCodeDialog.hide()">
        OK
      </q-btn>

    </ui-dialog>

  </div>

</template>

<script>
import { Notify } from 'quasar';
import { mapState } from 'vuex';
import tariffsCollection from '../collections/tariffs-collection.json';
import PageCard from '../components/pages/page-card';
import UiDialog from '../components/ui/ui-dialog';
import UiInput from '../components/ui/ui-input';
import QRCode from '../plugins/qr-code';
import Mixins from '../mixins';

export default {
  name: 'pages-page',
  components: {
    UiInput,
    UiDialog,
    PageCard,
  },
  mixins: [Mixins],
  data() {
    return {
      qrCodeGenerator: null,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.pages.items,
      user: (state) => state.users.sessionItem,
    }),
    tariff() {
      return tariffsCollection[this.user && this.user.subscribeType ? this.user.subscribeType : 'free'];
    },
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);

    this.$store.commit('pages/SET_PAGINATION', {
      limit: 999,
    });

    if (!this.items.length) this.$store.dispatch('pages/FETCH_ITEMS', {
      fields: [
        'id',
        'slug',
        'name',
        'published',
        'description',
        {
          name: 'image',
          fields: ['server', 'path'],
        },
      ],
    });
  },
  methods: {
    qrCodeShow(id) {
      this.$refs.qrCodeDialog.show({}, 'QR код страницы');

      this.$nextTick(() => {
        if (!this.qrCodeGenerator) this.qrCodeGenerator = new QRCode('qrcode', {
          useSVG: true,
          correctLevel: 0,
          colorDark: '#000000',
          colorLight: '#ffffff',
          text: 'https://bicard.pro/' + id,
        });

        this.$nextTick(() => {
          this.setDownloader('qrcode__link', 'qrcode__svg');
        });
      });
    },
    qrCodeHide() {
      if (this.qrCodeGenerator) {
        this.qrCodeGenerator.clear();
        this.qrCodeGenerator = null;
      }
    },
    submitCreatingDialog(item) {
      const errors = [];

      if (!item.title) errors.push('Укажите название');
      if (!item.slug) errors.push('Укажите адрес страницы');
      else if (item.slug.length < 3) errors.push('Адрес должен содержать минимум 3 символа');
      else if (!/^[a-z]+$/.test(item.slug)) {
        errors.push('Адрес должен содержать только строчные буквы латинского алфавита');
      } else if (item.slug.length > 20) errors.push('Адрес не может быть длиннее 20 символов');

      if (errors.length) {
        Notify.create({
          color: 'red',
          icon: 'eva-alert-circle-outline',
          message: errors.join('<br/>') || 'Неизвестная ошибка',
          actions: [{ icon: 'eva-close', color: 'white' }],
        });

        return;
      }

      this.$refs.creatingDialog.loading = true;
      this.$store.dispatch('pages/APPEND_ITEM', {
        args: { name: item.title, slug: item.slug },
        fields: ['slug'],
      }).then(({ slug }) => {
        this.$router.push({ name: 'page-editor', params: { slug } });
      }).catch((e) => {
        this.$refs.creatingDialog.loading = false;
        this.$error(e);
      });
    },
    setDownloader(linkId, svgId) {
      // get svg element.
      let svg = document.getElementById(svgId);

      // get svg source.
      let serializer = new XMLSerializer();
      let source = serializer.serializeToString(svg);

      // add name spaces.
      if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
      }

      // add xml declaration
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

      // convert svg source to URI data scheme.
      const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

      // set url value to a element's href attribute.
      document.getElementById(linkId).href = url;
    },
  },
};
</script>

<style lang="scss">
  @import "src/styles/quasar.variables.scss";

  #qrcode__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;

    #qrcode {
      width: 260px;
      height: 260px;

      &__link {
        width: 100%;
        display: block;
        color: $primary;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
      }
    }

  }

  .pages-page {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;

    .q-card {
      width: 100%;
      height: 336px;
    }

    &__card-add {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .q-icon {
        width: 44px;
        height: 44px;
        color: $primary;
        font-size: 22px;
        border-radius: 16px;
        margin-bottom: 10px;
        background-color: #F2F5FC;
      }
    }
  }

</style>
