<template>

  <!-- Error page -->
  <div class="error-page fixed-center text-center">

    <p>

      <img
        src="/images/system/sad.svg"
        style="width:30vw;max-width:150px;">

    </p>

    <p class="text-faded">

      Извините, произошла ошибка

      <strong>{{ code }}</strong>

    </p>

  </div>

</template>

<script>

export default {
  name: 'error-page',
  props: {
    code: {
      type: String,
      default: '404',
    },
  },
};

</script>
