export default {
  data() {
    return {
      siteKey: '',
      script: null,
      captcha: null,
    };
  },
  created() {
    window.captchaOnLoad = () => {
      console.log('captcha is loaded');
      this.captcha = window.grecaptcha;
    };
  },
  beforeDestroy() {
    if (this.script) {
      document.head.removeChild(this.script);

      this.script = null;
      this.captcha = null;
    }
  },
  methods: {
    captchaInit(siteKey) {
      if (!siteKey) {
        console.error('site-key is required');
        return;
      }

      this.siteKey = siteKey;

      this.script = document.createElement('script');
      this.script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=' + siteKey;
      this.script.async = true;
      this.script.defer = true;

      document.head.appendChild(this.script);
    },
    async captchaExecute(payload) {
      return this.captcha.execute(this.siteKey, payload);
    },
  },
};
