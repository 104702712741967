<template>

  <div class="layout__wrapper">

    <!-- Profile -->
    <q-card
      flat
      v-if="item"
      class="profile-page q-mb-xl">

      <h4 class="layout__title">
        Данные профиля
      </h4>

      <div class="layout__row">

        <ui-input-file
          label="Аватарка"
          class="q-mb-lg"
          :value="value.userpic"
          @remove="$store.commit('users/INPUT', {...value, userpic: null})"
          @input="v => $store.commit('users/INPUT', {...value, userpic: v})"/>

        <!-- Text Field - Login -->
        <ui-input
          label="Имя"
          class="q-mb-lg"
          :value="value.name"
          @input="v => $store.commit('users/INPUT', {...value, name: v})"/>

        <!-- Text Field - Login -->
        <ui-input
          label="E-mail"
          class="q-mb-lg"
          :value="value.email"
          @input="v => $store.commit('users/INPUT', {...value, email: v})"/>

      </div>

      <div style="width: fit-content;">

        <q-btn
          @click="save"
          label="Сохранить"
          :loading="loading"
          :disable="!changed"
          :color="changed ? 'positive' : 'secondary'"/>

        <q-tooltip
          v-if="!changed"
          :offset="[10, 10]"
          content-class="bg-info text-body2">
          Нет несохранённых изменений
        </q-tooltip>

      </div>

    </q-card>

    <!-- Pass -->
    <q-card
      flat
      v-if="item"
      class="profile-page">

      <h4 class="layout__title">
        Изменить пароль
      </h4>

      <div class="layout__row">

        <!-- Text Field -->
        <ui-input
          type="password"
          class="q-mb-lg"
          v-model="oldPass"
          label="Старый пароль"/>

        <!-- Text Field -->
        <ui-input
          type="password"
          class="q-mb-lg"
          v-model="newPass"
          label="Новый пароль"/>

        <!-- Text Field -->
        <ui-input
          type="password"
          class="q-mb-lg"
          v-model="repeatPass"
          label="Новый пароль повторно"/>

      </div>

      <div style="width: fit-content;">

        <q-btn
          @click="savePass"
          :loading="loading"
          label="Изменить пароль"
          :disable="!oldPass || !newPass || !repeatPass"
          :color="(!oldPass || !newPass || !repeatPass) ? 'secondary' : 'positive'"/>

        <q-tooltip
          :offset="[10, 10]"
          content-class="bg-info text-body2"
          v-if="!oldPass || !newPass || !repeatPass">
          Заполните все поля
        </q-tooltip>

      </div>

    </q-card>

    <q-spinner
      v-else
      size="3em"
      color="primary"
      :thickness="10"
      class="fixed-center"/>

  </div>

</template>

<script>
import isEqual from 'lodash/isEqual';
import { mapGetters, mapState } from 'vuex';
import UiInputFile from '../components/ui/ui-input-file';
import UiInput from '../components/ui/ui-input';

export default {
  name: 'profile-page',
  components: {
    UiInput,
    UiInputFile,
  },
  data() {
    return {
      oldPass: null,
      newPass: null,
      repeatPass: null,
    };
  },
  computed: {
    changed() {
      return !isEqual(
        this.item,
        this.value,
      );
    },
    ...mapGetters('global', {
      title: 'title',
    }),
    ...mapState({
      item: (state) => state.users.item,
      value: (state) => state.users.tempItem,
      loading: (state) => state.users.loading,
      sessionItem: (state) => state.users.sessionItem,
    }),
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);

    this.$store.commit('users/SET_ITEM', this.sessionItem);
  },
  methods: {
    save() {
      this.$store.commit('users/SET_LOADING', true);

      this.$store.dispatch('users/UPDATE_ITEM', {
        args: {
          name: this.value.name || '',
          email: this.value.email || '',
          userpic: this.value.userpic || null,
        },
        fields: [
          'id',
          'name',
          'email',
          {
            name: 'userpic',
            fields: ['id', 'path', 'server'],
          },
        ],
      }).then(() => {
        this.$success('Данные обновлены');
        this.$store.commit('users/RESET_LOADING');
      }).catch((e) => {
        this.$error(e);
        this.$store.commit('users/RESET_LOADING');
      });
    },
    savePass() {
      const { newPass, oldPass, repeatPass } = this;

      if (newPass.length < 4) {
        this.$error('Слишком короткий пароль');
        return;
      }

      if (newPass === oldPass) {
        this.$error('Новый пароль совпадает со старым');
        return;
      }

      if (newPass !== repeatPass) {
        this.$error('Пароли не совпадают');
        return;
      }

      this.$store.commit('users/SET_LOADING', true);

      this.$store.dispatch('users/NEW_PASS', {
        args: { oldPass, newPass },
      }).then(() => {
        this.$success('Данные обновлены');
        this.$store.commit('users/RESET_LOADING');
      }).catch((e) => {
        this.$error(e);
        this.$store.commit('users/RESET_LOADING');
      });
    },
  },
};
</script>

<style lang="scss">

  .profile-page {
  }

</style>
