<template>

  <q-scroll-area
    class="form-icon-select"
    style="width: 668px; height: 396px;">

    <div
      :key="icon"
      v-for="icon in items"
      class="form-icon-select__tile"
      @click="$emit('input', icon)">

      <q-icon :name="icon"/>

    </div>

  </q-scroll-area>

</template>

<script>
import FaCollection from '../../collections/icons-collection-fa.json';

export default {
  name: 'form-icon-select',
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const solid = FaCollection.solid.map((name) => `fas fa-${name}`);
      const brands = FaCollection.brands.map((name) => `fab fa-${name}`);
      const regular = FaCollection.regular.map((name) => `far fa-${name}`);

      this.items = [
        ...regular,
        ...brands,
        ...solid,
      ];
    },
  },
};
</script>

<style lang="scss">

  .form-icon-select {
    width: 100%;
    height: 100%;

    .absolute {
      display: grid;
      grid-gap: 12px;
      grid-template-columns: repeat(10, 56px);
    }

    &__tile {
      width: 56px;
      height: 56px;
      display: flex;
      color: #18232F;
      cursor: pointer;
      font-size: 22px;
      align-items: center;
      border-radius: 12px;
      flex-direction: column;
      justify-content: center;
      border: 1px solid #DBE0EE;
      background-color: #FFFFFF;
      transition: all .3s ease-in-out;

      &:hover {
        border: none;
        color: #FFFFFF;
        background-color: #316BFF;
      }
    }
  }

</style>
