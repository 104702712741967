import Notify from 'quasar/src/plugins/Notify.js';;
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import createStore from './store';
import * as utils from './utils';
import './plugins/quasar';

Vue.config.productionTip = false;

const store = createStore();

Vue.prototype.$info = (message, icon) => {
  Notify.create({
    message,
    color: 'primary',
    icon: icon || 'eva-info-outline',
    actions: [{ icon: 'eva-close', color: 'white' }],
  });
};

Vue.prototype.$success = (message, icon) => {
  Notify.create({
    message,
    color: 'positive',
    icon: icon || 'eva-checkmark-circle-2-outline',
    actions: [{ icon: 'eva-close', color: 'white' }],
  });
};

Vue.prototype.$error = (error, icon) => {
  let message;

  if (Array.isArray(error)) {
    let array = error;

    if (array[0].message) array = array.map((e) => e.message);
    if (typeof array[0] === 'string') message = array.join('<br/>');
    else {
      console.error(error);
      return;
    }
  } else if (typeof error === 'string') {
    message = error;
  } else {
    console.error(error);
    return;
  }

  Notify.create({
    color: 'red',
    icon: icon || 'eva-alert-circle-outline',
    message: message || 'Неизвестная ошибка',
    actions: [{ icon: 'eva-close', color: 'white' }],
  });
};

Vue.filter('cut', utils.cut);
Vue.filter('formatMoney', utils.formatMoney);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
