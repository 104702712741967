<template>

  <div
    class="page-design-card"
    @click="$emit('click')"
    :style="{ background: value.headerBg }">

    <div
      class="page-design-card__avatar"
      :style="{ background: value.headerTextColor }"/>

    <div
      class="page-design-card__title"
      :style="{ background: value.headerTextColor }"/>

    <div
      class="page-design-card__subtitle"
      :style="{ background: value.headerTextColor }"/>

    <div
      class="page-design-card__button"
      :style="{ boxShadow, background: value.bgColor }">

      <div
        class="page-design-card__button__icon"
        :style="{ background: value.iconBgColor }"/>

      <div
        class="page-design-card__button__title"
        :style="{ background: value.textColor }"/>

      <div
        class="page-design-card__button__subtitle"
        :style="{ background: value.textColor }"/>

    </div>

    <div
      class="page-design-card__body"
      :style="{ background: value.pageBg }"/>

  </div>

</template>

<script>
import { hexToRgb } from '../../utils';

export default {
  name: 'page-design-card',
  props: {
    value: {
      // fontFamily: String
      //
      // pageBg: String
      // headerBg: String
      // pageTextColor: String
      // headerTextColor: String
      //
      // shadowSize: Number
      // shadowColor: String
      // shadowOpacity: String
      // borderRadius: Number
      //
      // bgColor: String
      // textColor: String
      // iconColor: String
      // iconBgColor: String
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    boxShadow() {
      const { shadowSize, shadowColor, shadowOpacity } = this.value;
      const rgb = hexToRgb(shadowColor);

      if (!shadowSize || !shadowColor || !shadowOpacity || !rgb) return undefined;
      return `0 3px ${shadowSize / 4}px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${shadowOpacity})`;
    },
  },
};
</script>

<style lang="scss">

  .page-design-card {
    z-index: 1;
    width: 100%;
    height: 110px;
    cursor: pointer;
    position: relative;
    border-radius: 12px;
    margin-bottom: 12px;
    box-shadow: 0 20px 50px rgba(49, 107, 255, .12);

    &__avatar {
      top: 16px;
      left: 16px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      position: absolute;
    }

    &__title {
      top: 20px;
      left: 74px;
      right: 64px;
      height: 10px;
      position: absolute;
      border-radius: 3px;
    }

    &__subtitle {
      top: 37px;
      left: 74px;
      right: 42px;
      height: 8px;
      opacity: .5;
      position: absolute;
      border-radius: 3px;
    }

    &__button {
      z-index: 1;
      left: 74px;
      right: 16px;
      bottom: 16px;
      height: 36px;
      position: absolute;
      border-radius: 9px;

      &__icon {
        top: 5px;
        left: 5px;
        width: 26px;
        height: 26px;
        border-radius: 5px;
        position: absolute;
      }

      &__title {
        top: 9px;
        left: 39px;
        right: 64px;
        height: 7px;
        position: absolute;
        border-radius: 3px;
      }

      &__subtitle {
        top: 21px;
        left: 39px;
        right: 42px;
        height: 6px;
        opacity: .5;
        position: absolute;
        border-radius: 3px;
      }
    }

    &__body {
      z-index: 0;
      bottom: 0;
      width: 100%;
      height: 36px;
      position: absolute;
      border-radius: 0 0 10px 10px;
    }
  }

</style>
