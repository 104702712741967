<template>

  <div class="layout__wrapper">

    <div class="cards-page">

      <!-- Add Button -->
      <q-card
        flat
        class="cursor-pointer cards-page__card-add"
        @click="items.length < tariff.pages
          ? $refs.creatingDialog.show({}, 'Создание визитки')
          : $error('На вашем тарифе доступно не более ' + tariff.pages + ' визиток')">

        <q-icon name="eva-plus-outline"/>

        <div class="text-weight-medium">
          Создать визитку
        </div>

      </q-card>

      <!-- Items -->
      <card-card
        :id="item.id"
        :key="item.id"
        :name="item.name"
        v-for="item in items"
        :pageSlug="item.page ? item.page.slug : ''"/>

    </div>

    <!-- Creating Dialog -->
    <ui-dialog
      :width="520"
      ref="creatingDialog"
      @submit="submitCreatingDialog">

      <template
        slot-scope="{input, item}">

        <ui-input
          class="q-mb-md"
          label="Название"
          :value="item.name"
          @input="v => input({...item, name: v})"/>

      </template>

    </ui-dialog>

  </div>

</template>

<script>
import { mapState } from 'vuex';
import tariffsCollection from '../collections/tariffs-collection.json';
import CardCard from '../components/cards/card-card';
import UiDialog from '../components/ui/ui-dialog';
import UiInput from '../components/ui/ui-input';

export default {
  name: 'cards-page',
  components: {
    UiInput,
    UiDialog,
    CardCard,
  },
  computed: {
    ...mapState({
      items: (state) => state.cards.items,
      user: (state) => state.users.sessionItem,
    }),
    tariff() {
      return tariffsCollection[this.user && this.user.subscribeType ? this.user.subscribeType : 'free'];
    },
  },
  mounted() {
    const { title } = this.$route.meta;
    this.$store.commit('global/SET_TITLE', title);

    this.$store.commit('cards/SET_PAGINATION', {
      limit: 999,
    });

    if (!this.items.length) this.$store.dispatch('cards/FETCH_ITEMS', {
      fields: [
        'id',
        'name',
        {
          name: 'page',
          fields: [
            'id',
            'slug',
          ],
        },
      ],
    });
  },
  methods: {
    submitCreatingDialog(item) {
      const errors = [];

      if (!item.title) errors.push('Укажите название');

      this.$refs.creatingDialog.loading = true;
      this.$store.dispatch('cards/APPEND_ITEM', {
        args: { name: item.name },
        fields: ['id'],
      }).then(({ id }) => {
        this.$router.push({ name: 'card-editor', params: { id } });
      }).catch((e) => {
        this.$refs.creatingDialog.loading = false;
        this.$error(e);
      });
    },
  },
};
</script>

<style lang="scss">

  .cards-page {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr 1fr;

    .q-card {
      width: 100%;
      height: 336px;
    }

    &__card-add {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .q-icon {
        width: 44px;
        height: 44px;
        color: $primary;
        font-size: 22px;
        border-radius: 16px;
        margin-bottom: 10px;
        background-color: #F2F5FC;
      }
    }
  }

</style>
