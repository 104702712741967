<template>

  <div class="ui-input-icon">

    <!-- Input Tile -->
    <q-img
      :ratio="1"
      width="56px"
      height="56px"
      class="ui-input-icon__tile cursor-pointer"
      :src="value && value.path ? getFileUrl(value) : ''"
      @click="$refs.iconsDialog.show({}, 'Выберите иконку')">

      <q-icon
        v-if="value"
        :name="value"/>

      <q-btn
        flat
        v-else
        size="lg"
        icon="eva-plus"
        :loading="loading"
        text-color="primary"
        class="ui-input-icon__activator"/>

    </q-img>

    <div class="ui-input-icon__label__wrapper">

      <div class="ui-input-icon__label">
        {{ label }}
      </div>

      <div class="ui-input-icon__label__subtitle text-body2">
        {{ value | cut(20) }}
      </div>

    </div>

    <div v-if="value && $listeners['remove']" class="ui-input-icon__label__wrapper">

      <q-btn
        icon="eva-trash-outline"
        @click="$emit('remove')"/>

    </div>

    <!-- Icons List Dialog -->
    <ui-dialog
      :width="800"
      ref="iconsDialog">

      <form-icon-select
        @input="input"/>

      <div slot="actions"/>

    </ui-dialog>

  </div>

</template>

<script>
import Mixins from '../../mixins';
import UiDialog from './ui-dialog';
import FormIconSelect from '../forms/form-icon-select';

export default {
  name: 'ui-input-icon',
  components: { FormIconSelect, UiDialog },
  mixins: [Mixins],
  props: {
    label: {
      type: String,
      default: 'Иконка',
    },
    subtitle: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    input(icon) {
      this.$emit('input', icon);
      this.$refs.iconsDialog.hide();
    },
  },
};
</script>

<style lang="scss">

  .ui-input-icon {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 56px 1fr auto;

    .q-img {
      border-radius: 16px;
      background: #F2F5FC !important;

      &__content {
        display: flex;
        font-size: 26px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }

      .q-btn {
        color: $primary !important;
        background: #F2F5FC !important;

        &__wrapper {
          padding: 0;
        }
      }

      .q-icon {
        color: $primary !important;
      }
    }

    &__label {

      &__wrapper {
        display: flex;
        line-height: 16px;
        flex-direction: column;
        justify-content: center;

        .q-btn {
          width: 44px;
          height: 44px;
          border-radius: 12px;
          color: $primary !important;
          background: #F2F5FC !important;

          &__wrapper {
            padding: 0;
          }
        }

        .q-btn {
          width: 44px;
          height: 44px;
          border-radius: 12px;
          color: $primary !important;
          background: #F2F5FC !important;

          &__wrapper {
            padding: 0;
          }
        }
      }

      &__subtitle {
        color: #8E94A3;
      }
    }
  }

</style>
