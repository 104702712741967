import Vue from 'vue';
import VueRouter from 'vue-router';
import Cookies from 'quasar/src/plugins/Cookies.js';;
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'logout') {
    Cookies.remove('accessToken');
    next({ name: 'dialog-login' });
  }

  next();
});

export default router;
