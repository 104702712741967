<template>

  <div class="pass-reset-page dialog-layout__wrapper">

    <div class="dialog-layout__description">

      <div class="dialog-layout__description__logo">

        <q-img
          width="127px"
          height="25px"
          src="/assets/img/logo-white.svg"/>

      </div>

      <div class="dialog-layout__description__text">

        <div class="text-h3 text-white text-bold q-mb-sm">
          Вход
        </div>

        <div class="text-body1 text-white">
          Создавайте стильные
          <span class="text-no-wrap">онлайн-визитки </span>
          <span class="text-no-wrap">с QR-кодом, </span>
          ведущим на вашу страничку
        </div>

      </div>

      <div class="dialog-layout__description__actions">

        <div style="color: #C5DAFF;">
          Ещё нет аккаунта?
        </div>

        <q-btn
          flat
          no-caps
          color="white"
          class="no-padding"
          :to="{name: 'dialog-signup'}">
          Зарегистрироваться
        </q-btn>

      </div>

    </div>

    <!-- Form -->
    <q-form
      autofocus
      @submit="submit"
      class="dialog-layout__form">

      <div>

        <!-- Errors Banner -->
        <q-banner
          dense
          v-if="errors.length"
          class="text-white bg-red q-mb-md">

          <div
            :key="index"
            v-for="({ message }, index) in errors">
            {{ message }}
          </div>

        </q-banner>

        <div
          v-else
          class="q-mb-lg text-body1">
          Введите новый пароль
        </div>

        <!-- Text Field - Password -->
        <ui-input
          v-model="pass"
          label="Новый пароль"
          class="q-mb-md"
          :type="passDisplayed ? 'text' : 'password'">

          <!-- Icon Show -->
          <template v-slot:append>

            <q-icon
              size="xs"
              class="cursor-pointer"
              @click="passDisplayed = !passDisplayed"
              :name="passDisplayed ? 'fa fa-eye' : 'fa fa-eye-slash'"/>

          </template>

        </ui-input>

        <!-- Text Field - Password -->
        <ui-input
          v-model="passCheck"
          label="Пароль ещё раз"
          class="q-mb-sm"
          :type="passDisplayed ? 'text' : 'password'">

          <!-- Icon Show -->
          <template v-slot:append>

            <q-icon
              size="xs"
              class="cursor-pointer"
              @click="passDisplayed = !passDisplayed"
              :name="passDisplayed ? 'fa fa-eye' : 'fa fa-eye-slash'"/>

          </template>

        </ui-input>

      </div>

      <!-- Button Submit -->
      <q-btn
        no-caps
        size="lg"
        type="submit"
        color="primary"
        :loading="loading"
        class="full-width button-primary">
        Применить
      </q-btn>

    </q-form>

  </div>

</template>

<script>
import UiInput from '../../components/ui/ui-input';

export default {
  name: 'pass-reset-page',
  components: {
    UiInput,
  },
  props: {
    token: {
      type: String,
    },
  },
  data() {
    return {
      pass: '',
      passCheck: '',
      errors: [],
      loading: false,
      passDisplayed: false,
    };
  },
  methods: {
    submit() {
      this.errors = [];

      if (!this.pass || !this.passCheck) this.errors.push({
        message: 'Необходимо заполнить все поля',
      });

      if (this.errors.length) return;

      if (this.pass.length < 4) this.errors.push({
        message: 'Слишком короткий пароль',
      });

      if (this.pass !== this.passCheck) this.errors.push({
        message: 'Пароли не совпадают',
      });

      if (this.errors.length) return;

      this.loading = true;

      this.$store.dispatch('users/NEW_PASS', {
        token: this.token,
        newPass: this.pass,
      }).then(() => {
        this.$router.push({ name: 'dialog-login' });
      }).catch((errors) => {
        this.loading = false;
        this.errors = errors;
      });
    },
  },
};
</script>

<style lang="scss">

</style>
