<template>

  <div
    class="page-block"
    @click="$emit('select')">

    <div
      :class="{ active }"
      v-if="$listeners.remove"
      class="page-block__controls"
      @click.prevent.stop="() => null">

      <q-btn
        round
        icon="eva-trash-outline"
        @click.prevent.stop="$emit('remove')"/>

      <q-btn
        round
        :disabled="first"
        icon="eva-chevron-up-outline"
        @click.prevent.stop="$emit('moveUp')"/>

      <q-btn
        round
        :disabled="last"
        icon="eva-chevron-down-outline"
        @click.prevent.stop="$emit('moveDown')"/>

    </div>

    <page-block-button
      :design="design"
      :icon="value.icon"
      :title="value.title"
      :bg-color="value.bgColor"
      :subtitle="value.subtitle"
      :text-color="value.textColor"
      :icon-color="value.iconColor"
      v-if="value.type === 'button'"
      :icon-bg-color="value.iconBgColor"
      :image-link="value.image ? getFileUrl(value.image) : ''"/>

    <page-block-button
      :design="design"
      :title="value.title"
      :bg-color="value.bgColor"
      :subtitle="value.subtitle"
      :text-color="value.textColor"
      v-if="value.type === 'social'"
      :image-link="'https://fs.bicard.pro/icon/' + value.preset + '.svg'"/>

    <page-block-header
      :text="value.text"
      :title="value.title"
      :text-color="value.textColor"
      v-if="value.type === 'header'"
      :image-link="value.image ? getFileUrl(value.image) : ''"/>

  </div>

</template>

<script>
import PageBlockButton from './blocks/page-block-button';
import PageBlockHeader from './blocks/page-block-header';
import Mixins from '../../mixins';

export default {
  name: 'page-block',
  components: {
    PageBlockHeader,
    PageBlockButton,
  },
  mixins: [Mixins],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    design: {
      type: Object,
      default: () => ({}),
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {},
};
</script>

<style lang="scss">

  .page-block {
    cursor: pointer;
    position: relative;

    &__controls {
      top: 13px;
      left: -166px;
      height: 44px;
      padding: 8px;
      color: $primary;
      position: absolute;
      border-radius: 12px;
      background-color: #FFFFFF;
      transition: all .3s ease-in-out;

      .q-btn {
        padding: 0;
        width: 28px;
        height: 28px;

        &__wrapper {
          width: 100%;
          height: 100%;
          min-width: unset;
          min-height: unset;

          .eva-chevron-up-outline,
          .eva-chevron-down-outline {
            font-size: 24px;
          }
        }
      }

      &.active {
        color: #FFFFFF;
        background-color: $primary;
      }
    }
  }

</style>
