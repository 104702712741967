<template>

  <q-select
    filled
    :value="value"
    :label="label"
    :options="items"
    class="ui-select"
    :emit-value="emitValue"
    :map-options="mapOptions"
    @input="(v) => $emit('input', v)">

    <!-- Set Scoped Slots From Parent -->
    <template
      :slot="slot"
      slot-scope="scope"
      v-for="slot in Object.keys($scopedSlots)">

      <slot :name="slot" v-bind="scope"/>

    </template>

  </q-select>

</template>

<script>
export default {
  name: 'ui-select',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    emitValue: {
      type: Boolean,
      default: false,
    },
    mapOptions: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">

  .ui-select {

    .q-field__control {
      padding: 0 20px;
      overflow: hidden;
      background: #F2F5FC;
      border-radius: 12px;
      border: none !important;

      &:before {
        opacity: 0!important;
      }
    }
  }

</style>
