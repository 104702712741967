<template>

  <q-card flat class="card-card">

    <div class="card-card__info">

      <h4>
        {{ name }}
      </h4>

    </div>

    <div class="card-card__actions">

      <q-btn
        no-caps
        icon="eva-edit"
        label="Редактировать"
        @click="$router.push({ name: 'card-editor', params: { id } })"/>

      <q-btn
        no-caps
        label="Настройки"
        icon="fas fa-cogs"
        @click="$router.push({ name: 'card-settings', params: { id } })"/>

      <q-btn
        no-caps
        type="a"
        label="Страница"
        target="__blank"
        :disable="!pageSlug"
        icon="eva-external-link-outline"
        :href="'/page/' + pageSlug + '/edit'"/>

      <q-btn
        no-caps
        label="Скачать"
        icon="fas fa-download"
        @click="$router.push({ name: 'card-download', params: { id } })"/>

    </div>

  </q-card>

</template>

<script>
import Mixins from '../../mixins';

export default {
  name: 'card-card',
  mixins: [Mixins],
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    pageSlug: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">

  .card-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__info {
      display: flex;
      flex-wrap: nowrap;
      line-height: 56px;

      h4 {
        display: flex;
        align-items: center;
      }
    }

    &__actions {
      display: grid;
      row-gap: 10px;
      column-gap: 16px;
      grid-template-rows: 44px 44px;
      grid-template-columns: 1fr 1fr;

      .q-btn {
        line-height: 44px;
        border-radius: 12px;
        background-color: #F2F5FC;

        &__wrapper {
          padding: 0 10px;
        }

        .q-icon {
          color: $primary;
        }
      }

      &__link {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 44px;
      }
    }
  }

</style>
